<template lang="pug">
div
  v-dialog(fullscreen :value='dialogOpen' transition='dialog-bottom-transition' @keydown.esc='preClose')

    v-container.px-0.pb-0(fluid grid-list-lg text-left :style="$vuetify.breakpoint.xsOnly && showImpressions ? 'padding-top: 44px;' : 'padding-top: 0;'")
      v-app-bar(dark color='primary' dense fixed)
        v-btn.close-edit-dialog-btn(icon dark @click='preClose')
          v-icon mdi-close
        v-toolbar-title(v-if='dialogOpen')
          | {{ pageTitle }}
        v-spacer

        v-btn.edit-line-dialog-save-btn.primary--text(color='white' :loading='saving' @click='saveWrapper()' :class="{'mr-4': $vuetify.breakpoint.smAndUp}")
          | {{ instance === "editLine" ? 'Save' : 'Add line' }}

      v-scroll-y-transition
        v-btn#toggle-map-btn.primary--text.mx-auto.mb-4(
          style='position: fixed; bottom:0; left: 0; right: 0; z-index: 2;'
          color='white'
          :width="!showMapOnly? 95 : 150"
          rounded
          @click='toggleView'
          v-if='$vuetify.breakpoint.xsOnly'
          elevation=12
        )
          v-icon.mr-2(color='primary') {{ showMapOnly? 'mdi-menu' : 'mdi-map-outline' }}
          | {{ showMapOnly? 'targeting' : 'map' }}

      v-row.ma-0(style='padding-top: 48px; background-color: #f8f9fa; min-height: 100vh;')
        v-fade-transition
          v-col.pa-0.pa-sm-3(
            cols=12 md=6 lg=5 xl=5
            style='min-height: calc(100vh - 48px);'
            v-if='showPage'
            v-show="!showMapOnly || $vuetify.breakpoint.smAndUp"
          )
            v-expansion-panels(v-model='panelsLineModel')
              v-expansion-panel#line-order-name-panel(ref='panelLineRef')
                v-expansion-panel-content
                  v-card(flat color="transparent")
                    v-card-text.pa-0
                      v-row.my-0(row wrap justify="end")
                        v-col.pb-0(cols=12 sm=6 lg=8 text-md-left)
                          v-text-field(
                            id='line-order-name-field'
                            :label='panelsConfig.nameLabel'
                            :loading='loading'
                            :placeholder='namePlaceholder'
                            :value='name'
                            :rules='$store.getters["user/isForAdServer"] ? [rules.required] : []'
                            @input='nameChanged')
                        v-col.pb-0(cols=12 sm=6 lg=4 text-md-left)
                          v-text-field(
                            id='line-order-external-id'
                            label='External ID'
                            :value='externalId'
                            :loading='loading'
                            @input='externalIdChanged')
                      scheduleSection(
                        v-if="isOnAuctionPackagePage"
                        parent='editLine'
                        @storeUpdated='updateForecast'
                        :host="{ name: 'createCampaign' }"
                        :isProposalLine='false'
                        :isOnAuctionPackagePage='true'
                      )
                      creativeFormatSection(
                        v-if="isOnAuctionPackagePage"
                        :initMergeCreativeFormatRatio='data.mergeCreativeFormatRatio'
                        :initMustUseReencodedDspCreative='data.mustUseReencodedDspCreative'
                      )

            v-expansion-panels.mt-4(multiple v-model='panelsStateController')
              panelInventorySource.dynamic-panel(
                v-if="componentConfig.campaigns.isDealsShown"
                :loadingLine='loading'
                :campaignType= 'campaignType'
                :canSeeDspPartnerFields='canSeeDspPartnerFields'
                :instance= 'instance'
                :parentOrganizationId='campaignBuyerId || auctionPackageOrganizationId'
                @updateForecast='updateForecast',
                :isOnAuctionPackagePage='isOnAuctionPackagePage',
                :initUsePublicExchange='usePublicExchange',
                :initMarket='exchange.key')

              panel-audience.dynamic-panel#audience-panel(
                @updateForecast='updateForecast'
                :parent='instance'
                :parentLoading='loading'
                :downloadingInventory='downloadingInventory'
                :tab='audiencePanelTab'
                :isOnAuctionPackagePage='isOnAuctionPackagePage'
                @switchTab='switchPanelAudienceTab'
                @inventoryListChanged='updateTableToMapSync'
                @downloadInventory='downloadInventory')

              panel-moments.dynamic-panel(
                :momentId='momentId'
                :buyerId='campaignBuyerId'
                @momentUpdated='resumeSaving'
                @momentCreated='resumeSaving'
                @momentsError='momentsError'
                @momentLoaded='saveMomentInitialDefinition')

              panelBudgetAndScheduleWithGoals.dynamic-panel(
                v-if="!loading && componentConfig.campaigns.isGoalsShown && !isOnAuctionPackagePage"
                parent='editLine'
                :isProposalLine='isProposalLine'
                :instance='instance'
                @updateForecast='updateForecast'
                @formValidationUpdated='updateValidationStatus')

              panel-budget-and-schedule.dynamic-panel(
                v-else-if='!loading && !isOnAuctionPackagePage'
                parent='editLine'
                :isProposalLine='isProposalLine'
                :instance='instance'
                :buyerMarkup='buyerMarkup'
                @updateForecast='updateForecast'
                @formValidationUpdated='updateValidationStatus')

              panelLineGoal.dynamic-panel(
                v-if="componentConfig.campaigns.isGoalsShown && !isOnAuctionPackagePage"
                :loadingLine='loading'
                @formValidationUpdated='updateValidationStatus')

              panel-line-priority.dynamic-panel.priority(
                v-if="componentConfig.campaigns.isLinePriorityShown"
                :parent-loading="loading")

              panel-creatives.dynamic-panel(v-if="!isProposalLine && !loading && !isOnAuctionPackagePage && userPermissions('creative').read.default" :line='data' @updateForecast='updateForecast')
              panelCost.dynamic-panel#ap-cost-panel(v-if='panelsConfig.isCostShown' :existingFees='data.fees')

        //- Map & Forecast
        v-col.ma-0.pa-0(cols=12 md=6 lg=7 xl=7 order='first' order-md='last' v-if='showPage')
          div.sticky-column
            InventoryMap(
              v-if='!loading && mapExists'
              v-show='showMapOnly || $vuetify.breakpoint.smAndUp'
              ref='mapComponent'
              :bound-initial-forecast='componentConfig.campaigns.boundInitialForecastCall'
              :compact-popups='$vuetify.breakpoint.xsOnly'
              :default-latitude='marketValueFromExchange.mapCenter[1]'
              :default-longitude='marketValueFromExchange.mapCenter[0]'
              :default-zoom='marketValueFromExchange.mapZoom'
              :filter-on-move='filterOnMove'
              :forecast-inventory='forecastInventory'
              :forecast-inventory-loading='isForecastInventoryLoading'
              :forecast-loading='forecastLoading'
              :geo-targets='mapGeoTargets'
              :get-venue-details-func='getVenueDetails'
              :height='mapHeight'
              :highlighted-geo-target='highlightedGeoTargetForMap'
              :impressions='impressions'
              :map-box-token='mapboxToken'
              :mapId='mapId'
              :readonly='$vuetify.breakpoint.xsOnly'
              :show-details-button='$vuetify.breakpoint.smAndUp'
              :show-fullscreen-button='$vuetify.breakpoint.smAndUp'
              :show-impressions='$vuetify.breakpoint.smAndUp && showImpressions'
              :show-inventory-summary='$vuetify.breakpoint.smAndUp'
              :show-venue-popup='true'
              :targeted-venues='targetedVenues'
              :targeted-screens='targetedScreens'
              :unit='marketValueFromExchange.distanceUnit'
              :zoom-on-updated-forecast='zoomOnUpdatedForecast'
              :disable-recentering='disableRecentering'
              :resolve-geo-json-func='resolveGeoJson'
              @filterOnMoveChecked='filterOnMoveChecked'
              @focusedGeoIndexChanged='setFocusedGeoInfo'
              @geoTargetAdded='geoTargetAdded'
              @geoTargetRemoved='geoTargetRemoved'
              @geoTargetSelected='setFocusedGeoInfo'
              @geoTargetUnselected='unsetFocusedGeoInfo'
              @geoTargetUpdated='geoTargetUpdated'
              @mapBoundsUpdated='mapBoundsUpdated'
              @hideDetails='hideDetails'
              @seeDetails='seeDetails'
            )
              template(v-slot:fullscreen-drawer-content='')
                forecast-inventory(
                  :loading='isForecastVenuesLoading'
                  :downloadingInventory='downloadingInventory'
                  :items='venues'
                  :total-items='targetedVenues'
                  :page='pagination.page'
                  :has-more-items='pagination.hasMorePages'
                  :updateVenueInclusionEnabled='true'
                  @updateVenueInclusion='updateVenueInclusion'
                  @inventoryListChanged="updateTableToMapSync"
                  @downloadInventory='downloadInventory'
                )

          forecastSummary(
            ref='mobileForecastSummary'
            v-if="$vuetify.breakpoint.xsOnly"
            parent='editDialog'
            :showImpressions='showImpressions')

    loadDealsDialog(:openDialogEvent='showDealsDialog' @updateForecast='updateForecast')

    v-dialog(v-model="isConfirmationOpen" maxWidth=380)
      v-card.close-confirmation
        v-card-title Discard unsaved changes?
        v-card-actions
          v-spacer
          v-btn.cc-cancel(text @click="isConfirmationOpen=false") Cancel
          v-btn.cc-save(text @click='saveWrapper()' :loading="saving") save
          v-btn.cc-discard(text color='primary' @click="close(true)") discard
</template>
<script>
import panelLineGoal from '@/components/panelLineGoal.vue'
import panelInventorySource from '@/components/panelInventorySource.vue'
import panelAudience from '@/components/panelAudience.vue'
import panelLinePriority from '@/components/panelLinePriority.vue'
import panelMoments from '@/components/panelMoments.vue'
import panelBudgetAndSchedule from '@/components/panelBudgetAndSchedule.vue'
import panelBudgetAndScheduleWithGoals from '@/components/panelBudgetAndScheduleWithGoals.vue'
import loadDealsDialog from '@/views/loadDealsDialog.vue'
import panelCreatives from '@/components/panelCreatives.vue'
import panelCost from '@/components/panelCost.vue'
import forecastSummary from '@/components/forecast.summary.vue'
import scheduleSection from '@/components/audience.schedule.vue'
import creativeFormatSection from '@/components/auctionPackageCreativeFormatForm.vue'
import forecastInventory from '@/components/forecast.inventory.vue'

import _ from 'lodash'
import moment from 'moment'

import audienceApi from '@/services/audience.api'
import campaignsApi from '@/services/campaigns.api'
import userApi from '@/services/user.api'
import csvService from '@/services/csv.service'
import venuesAPI from '@/services/venues.api'

import helperService from '@/services/helpers.service'
import audienceService from '@/services/audience.service'
import tracking from '@/services/tracking'
import geoService from '@/services/geo.service'
import geoBoundingBoxService from '@/services/geo.boundingbox.service'
import componentConfigService from '@/services/componentConfig'
import campaignValidationService from '@/services/campaignValidation.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'

import campsiteConfig from '@/config/campsite.config'
import { OrganizationTypeEnum, DatetimeFormattingEnum } from '@/enums'

import { InventoryMap } from '@ayudasystems/campaign-targeting'

export default {
  components: {
    panelLineGoal,
    panelLinePriority,
    panelInventorySource,
    panelAudience,
    panelMoments,
    panelBudgetAndSchedule,
    panelBudgetAndScheduleWithGoals,
    panelCreatives,
    panelCost,
    InventoryMap,
    forecastSummary,
    loadDealsDialog,
    scheduleSection,
    creativeFormatSection,
    forecastInventory
  },
  props: ['data', 'campaign', 'dialogOpen', 'src'],
  data () {
    return {
      showPage: false,
      loadingLine: true,
      loadingExchangeTargeting: true,
      saving: false,

      buyerMarkup: 0,
      details: null,

      panelsLineModel: 0,
      panelsStateController: [],

      forecastOptionsLastVersion: null,

      isConfirmationOpen: false,

      audiencePanelTab: 0,
      showMapOnly: false,
      mapExists: true,
      downloadingInventory: false,

      isMomentUpdated: false,
      momentInitialDefinition: null,
      advertisers: [],

      showDealsDialog: false,

      validationStatus: {
        isValid: true,
        field: ''
      },
      rules: {
        required: (value) => !!value || 'Required.'
      },
      mapboxToken: process.env.VUE_APP_MAPBOX_TOKEN,
      isFilterOnMoveVisible: false,
      updateGeoTargetsJsonCT: null,
      geoTargetsWithGeoJson: [],
      disableRecentering: false,
      highlightedGeoTargetForMap: null,
      geoTargetUpdatedFromMap: false
    }
  },
  created: function () {
    this.$root.$on('toggleLoadDealsDialog', () => {
      this.showDealsDialog = !this.showDealsDialog
    })
    this.geoTargetsWithGeoJson = this.selectedGeoTargets
  },
  mounted () {
    this.$root.$on('validateLineOrderName', () => {
      this.updateValidationStatus(!!this.name, 'lineOrderName')
    })
  },
  computed: {
    loading () {
      return Boolean(this.loadingLine || this.loadingExchangeTargeting)
    },
    ids () {
      if (this.isOnAuctionPackagePage) {
        return { campaignId: 0, lineId: this.data.lineId, accountId: 0 }
      }
      const lineId = this.data ? this.data.id : null
      return { campaignId: this.campaign.id, lineId, accountId: this.campaign.accountId }
    },
    isProposalLine () {
      return !this.isOnAuctionPackagePage && this.campaign.status === 'Proposal'
    },
    exchanges () {
      return this.$store.getters['general/getExchanges']
    },
    industries () {
      return this.$store.getters['general/getIndustries']
    },
    advertiser () {
      var getter = this.isProposalLine ? 'proposals/advertiser' : 'campaignReport/getAccount'
      return this.$store.getters[getter]
    },
    exchange () {
      return this.$store.getters['general/currentPageExchange']
    },
    assignments () {
      return this.$store.getters['campaignReport/getAssignments'].filter(a => this.data && a.lineId === this.data.id)
    },
    assignedCreatives () {
      return this.$store.getters['campaignReport/getAssignedCreatives']
    },
    newCreatives () {
      return this.$store.getters['createCampaign/getCreatives']
    },
    isForecastInventoryLoading () {
      return this.$store.getters['audience/forecastInventoryLoading']
    },
    forecastInventory () {
      return this.$store.getters['audience/forecastInventory']
    },
    selectedGeoTargets () {
      return this.$store.getters['audience/selectedGeoTargets']
    },
    pageTitle () {
      return this.instance === 'editLine'
        ? this.data.name
        : this.$store.getters[this.getName]
    },
    campaignType () {
      if (this.isOnAuctionPackagePage) {
        return OrganizationTypeEnum.PARTNER_DSP.toString()
      }
      return this.campaign?.organizationType || OrganizationTypeEnum.DEFAULT.toString()
    },
    redirectRoute () {
      return this.isProposalLine
        ? { name: 'Proposal', params: { id: this.$route.params.campaignId } }
        : { name: 'report-line', params: this.$route.params }
    },
    forecast () {
      return this.$store.getters['audience/forecast']
    },
    forecastLoading () {
      return this.$store.getters['audience/forecastLoading']
    },
    targetedVenues () {
      return this.forecast.inventory.numberOfVenues
    },
    targetedScreens () {
      return this.forecast.inventory.numberOfScreens
    },
    pagination () {
      return this.$store.getters['audience/getPagination']
    },
    validDeals () {
      return this.$store.getters['createCampaign/validDeals']
    },
    instance () {
      return this.data ? 'editLine' : 'createCampaign'
    },
    mapId () {
      return 'mapForEditLine' + this.ids.lineId
    },
    filterAsImove () {
      return this.$store.getters['audience/filterAsImove']
    },
    canOverwrite () {
      return this.$store.getters['createCampaign/canOverwriteWithDeal']
    },
    isSelfServePlanningEnabled () {
      return this.$store.getters['general/isSelfServePlanningEnabled']
    },
    canSeeDspPartnerFields () {
      return this.isSelfServePlanningEnabled && this.campaignType === OrganizationTypeEnum.PARTNER_DSP.toString()
    },
    getCommonForecastOptions () {
      var options = {}
      if (this.isOnAuctionPackagePage) {
        options = this.$store.getters['auctionPackage/getCommonForecastOptions']
      } else {
        options = this.$store.getters['createCampaign/getCommonForecastOptions']
        if (this.canSeeDspPartnerFields) {
          options.percentiles = [1, 100]
        }
      }
      if (this.instance === 'editLine') {
        options.mapId = parseInt(this.ids.lineId)
      }
      return options
    },
    momentId () {
      return this.data
        ? this.data.momentId
        : null
    },
    isOnAuctionPackagePage () {
      return this.$route.path.includes('/auction-package') && this.$store.getters['general/isAuctionPackageVisible']
    },
    getName () {
      return this.isOnAuctionPackagePage ? 'auctionPackage/getName' : 'createCampaign/getLineName'
    },
    getExternalId () {
      return this.isOnAuctionPackagePage ? 'auctionPackage/getExternalId' : 'createCampaign/getLineExternalId'
    },
    setName () {
      return this.isOnAuctionPackagePage ? 'auctionPackage/setName' : 'createCampaign/setLineName'
    },
    setExternalId () {
      return this.isOnAuctionPackagePage ? 'auctionPackage/setExternalId' : 'createCampaign/setLineExternalId'
    },
    panelsConfig () {
      const lineVue = {
        auctionPackage: {
          nameLabel: 'Auction Package Name',
          isCostShown: this.userPermissions('auctionPackage')?.update?.fees
        },
        line: {
          nameLabel: 'Line Order Name',
          isCostShown: false
        }
      }

      return this.isOnAuctionPackagePage
        ? lineVue.auctionPackage
        : lineVue.line
    },
    hasMoment () {
      return this.$store.getters['createCampaign/hasMoment']
    },
    isMomentValid () {
      return this.$store.getters['createCampaign/isMomentValid']
    },
    name () {
      return this.$store.getters[this.getName]
    },
    externalId () {
      return this.$store.getters[this.getExternalId]
    },
    namePlaceholder () {
      if (this.$store.getters['user/isForAdServer']) {
        return ''
      }
      const defaultName = this.marketDefaultValues.lineName
      return (defaultName && !this.loadingLine) ? defaultName : ''
    },
    campaignBuyerId () {
      return this.campaign?.buyerId
    },
    auctionPackageOrganizationId () {
      return this.data.organization.id
    },
    usePublicExchange () {
      return this.data?.usePublicExchange
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    userPermissions () {
      return this.$store.getters['user/permissions']
    },
    panelsToValidate () {
      return [
        ...this.$store.getters['user/isForAdServer'] ? ['lineOrderName'] : [],
        'budgetAndSchedule',
        'lineGoal',
        'linePriority'
      ]
    },
    panelsOrder () {
      if (this.isOnAuctionPackagePage) {
        return ['audience', 'moments', 'cost']
      }

      const panels = [
        {
          name: 'inventorySource',
          isShown: !this.$store.getters['user/isForAdServer']
        },
        {
          name: 'audience',
          isShown: true
        },
        {
          name: 'moments',
          isShown: true
        },
        {
          name: 'budget',
          isShown: true
        },
        {
          name: 'lineGoal',
          isShown: this.$store.getters['user/isForAdServer']
        },
        {
          name: 'linePriority',
          isShown: this.$store.getters['user/isForAdServer']
        },
        {
          name: 'creatives',
          isShown: this.userPermissions('creative').read.default
        }
      ]

      return panels.filter(panel => panel.isShown).map(panel => panel.name)
    },
    showImpressions () {
      return this.componentConfig.campaigns.impressions.useEstimated
    },
    marketDefaultValues () {
      return this.$store.getters['general/marketDefaultValues']
    },
    marketValueFromExchange () {
      return this.$store.getters['general/marketValueFromExchange'](this.exchange.key)
    },
    mapHeight () {
      const offsetHeight = this.$vuetify.breakpoint.xsOnly && this.showImpressions
        ? campsiteConfig.barsHeight.appNavbar + campsiteConfig.barsHeight.forecastBar
        : campsiteConfig.barsHeight.appNavbar
      return 'calc(100vh - ' + offsetHeight + 'px)'
    },
    impressions () {
      const isTargetedImpressionsAvailable =
        this.$store.getters['createCampaign/getMaxCpm'] &&
        this.$store.getters['createCampaign/getBudget'] &&
        !!this.forecast.budget

      return {
        total: this.isForecastImpressionsAvailable ? this.forecast?.impressions?.value : null,
        targeted: isTargetedImpressionsAvailable ? this.forecast?.budget?.impressions?.value : null
      }
    },
    isForecastImpressionsAvailable () {
      return !this.hasMoment && (this.$store.getters['createCampaign/deals'].length === 0 || this.$flags.canSeeAuctionPackagePrivateDealsTargetedImpressions.isEnabled())
    },
    mapGeoTargets () {
      return this.geoTargetsWithGeoJson
        .filter(geoTarget => geoTarget.type !== 'point_of_interest')
        .map(geoTarget => this.convertGeoTargetToMapFormat(geoTarget))
    },
    mapBounds () {
      return this.$store.getters['audience/getMapBounds']
    },
    focusedGeoInfo () {
      return this.$store.getters['audience/getFocusedGeoInfo']
    },
    highlightedGeoTarget () {
      const focusedGeoTargetInfo = this.focusedGeoInfo
      return focusedGeoTargetInfo.index === -1 ? null : { geoTarget: this.selectedGeoTargets[focusedGeoTargetInfo.index], centerMap: focusedGeoTargetInfo.centerMap }
    },
    filterOnMove () {
      return {
        isVisible: this.isFilterOnMoveVisible,
        value: this.filterAsImove
      }
    },
    zoomOnUpdatedForecast () {
      return this.pagination?.filtering || false
    },
    forecastVenues () {
      return this.$store.getters['audience/forecastVenues']?.venues || []
    },
    isForecastVenuesLoading () {
      return this.$store.getters['audience/forecastVenuesLoading']
    },
    venues () {
      return this.forecastVenues.map(x => {
        const venueObjInStore = this.selectedGeoTargets.find(y => y.value === x.id.toString())
        const isIncluded = !venueObjInStore || venueObjInStore.isIncluded
        return { ...x, isIncluded }
      })
    },
    mapViewPort () {
      return this.$store.getters['audience/getMapViewPort']
    }
  },
  watch: {
    dialogOpen (newVal) {
      if (newVal) {
        this.$store.dispatch('general/getExchangesTargeting').then(() => {
          this.loadingExchangeTargeting = false
        })

        var i = this.panelsOrder.indexOf(this.src)
        if (i > -1) {
          this.panelsStateController = [i]
        } else {
          this.openDefaultPanels()
        }

        this.forecastOptionsLastVersion = null

        if (this.isOnAuctionPackagePage) {
          this.$store.commit('auctionPackage/resetAuctionPackage')
          this.$store.commit('createCampaign/setDeals', [])
        } else {
          this.$store.commit('createCampaign/resetCreateCampaign')
          this.$store.commit('audience/resetAudience')
        }

        if (this.isOnAuctionPackagePage) {
          this.$store.commit('createCampaign/setMaxCpm', null)
          setTimeout(() => {
            this.getData()
            this.showPage = true
          }, 360)
          return
        }
        this.getBuyerMarkup()
        this.getOrganizationAdvertisers(this.campaign.buyerId)
          .then(advertisers => {
            this.advertisers = advertisers
            this.getAdvertiserOrganization()
          })

        setTimeout(() => {
          this.instance === 'editLine'
            ? this.getData()
            : this.initializeNewLine()

          this.showPage = true
        }, 360)
      }
    },
    mapBounds (_, oldVal) {
      if (oldVal === null) {
        this.updateForecast()
      } else {
        this.updateForecastInventoryAndVenues()
      }
    },
    async selectedGeoTargets () {
      await this.updateGeoTargetsJson(this.geoTargetUpdatedFromMap)
      this.geoTargetUpdatedFromMap = false
    },
    highlightedGeoTarget: {
      immediate: true,
      async handler (newValue) {
        let geoTargetForMap = null
        let disableRecentering = true
        if (newValue?.geoTarget) {
          const geoTargetWithGeojson = await geoService.resolveGeoJsonForGeoTarget(newValue.geoTarget)
          geoTargetForMap = this.convertGeoTargetToMapFormat(geoTargetWithGeojson)
          disableRecentering = !newValue.centerMap
        }
        this.disableRecentering = disableRecentering
        this.highlightedGeoTargetForMap = geoTargetForMap
      }
    }

  },
  methods: {
    getBuyerMarkup () {
      if (!this.buyerMarkup) {
        userApi.getOrganization(this.campaign.buyerId)
          .then(buyerOrg => {
            this.buyerMarkup = buyerOrg.markup
          })
      }
    },
    getAdvertiserOrganization () {
      const fullAdvertiserAccount = this.advertisers.find(a => a.id === this.advertiser.id)

      if (fullAdvertiserAccount) {
        userApi.getOrganization(fullAdvertiserAccount.advertiserId)
          .then(advertiserOrg => {
            let industry
            if (advertiserOrg.industry) {
              industry = this.industries.find(x => x.name && x.name === advertiserOrg.industry)
            }

            if (!industry) {
              industry = this.$store.getters['createCampaign/getDefaultIndustry']
            }

            this.$store.commit('createCampaign/setAdvertiserAndIndustry', { advertiser: this.advertiser, industry })
          })
      }
    },
    getOrganizationAdvertisers (organizationId) {
      return campaignsApi.getAdvertisers(organizationId)
        .then(res => res.data)
    },
    getData () {
      var geoPromise = this.data.geoLoaded || this.isOnAuctionPackagePage
        ? new Promise((resolve, reject) => {
          resolve(Object.assign({}, this.data))
        })
        : this.$store.dispatch('campaignReport/getLine', this.data.id)

      geoPromise
        .then(async dataWithGeo => {
          // should we make a copy, so we can reset properly ..?
          // why use details instead of line directly if everything is "sent" to Store ..?
          this.details = dataWithGeo

          var exchangeId = this.data.exchangeId

          if (!exchangeId && this.data.exchange.id) {
            exchangeId = this.data.exchange.id
          }

          if (!this.details.audienceSegments) {
            this.details.audienceSegments = this.details.targeting
            delete this.details.targeting
          }

          this.$store.commit(this.setName, this.details.name)
          this.$store.commit(this.setExternalId, this.details.externalId)
          this.$store.commit('createCampaign/setMomentId', this.details.momentId)

          var endDate = new Date()
          endDate.setDate(endDate.getDate() + 7)

          if (this.details.startDate || this.details.endDate || !this.isOnAuctionPackagePage) {
            this.$store.commit('createCampaign/setStartDate', moment(this.details.startDate || new Date().toISOString()).format(DatetimeFormattingEnum.DATE_ONLY))
            this.$store.commit('createCampaign/setStartTime', moment(this.details.startDate).format(DatetimeFormattingEnum.TIME_ONLY))
            this.$store.commit('createCampaign/setEndDate', moment(this.details.endDate || endDate).format(DatetimeFormattingEnum.DATE_ONLY))
            this.$store.commit('createCampaign/setEndTime', moment(this.details.endDate).format(DatetimeFormattingEnum.ROUNDED_HOUR))
            if (this.isOnAuctionPackagePage) {
              this.$store.commit('auctionPackage/setScheduleOption', 'schedule')
            }
          } else {
            this.$store.commit('auctionPackage/setScheduleOption', 'always')
          }

          this.$store.commit('createCampaign/storeExchangeDetails', { exchange: this.exchanges.find(x => x.id === exchangeId) })

          // DEALS
          if (this.details.deals && this.details.deals.length) {
            const formattedDeals = this.details.deals.map(x => {
              return x.deal
                ? Object.assign({}, x.deal, { auctionType: x.auctionType })
                : helperService.generateUnknownDealObj(x.code)
            })
            this.$store.commit('createCampaign/setDeals', formattedDeals)
          }

          if (this.isOnAuctionPackagePage) {
            this.$store.commit('auctionPackage/storeExchangeDetails', { exchange: this.data.exchange })
            await this.$store.dispatch('audience/loadAudience', this.details)
            var selectedGeoTargetsNoGeoJSON = geoService.removeGeoJSON(this.selectedGeoTargets)
            this.details.geography = geoService.geoTargetsToApiFormatting(selectedGeoTargetsNoGeoJSON)
            this.loadingLine = false
            return
          }

          this.$store.commit('createCampaign/setBudget', this.details.budget)
          this.$store.commit('createCampaign/setMaxCpm', this.details.maxCpm)
          this.$store.commit('createCampaign/setLinePriority', this.details.priority)

          // ASSIGNMENTS & CREATIVES
          this.$store.dispatch('campaignReport/getAssignedCreatives', this.assignments)
            .then(async assignedCreatives => {
              // make a copy before sending to "Create Campaign" store
              // otherwise "Campaign Report" store's creatives will be updated along "Create Campaign"
              this.$store.commit('createCampaign/setCreatives', [...assignedCreatives])

              await this.$store.dispatch('audience/loadAudience', this.details)

              if (this.dialogOpen) {
                this.loadingLine = false
              }

              if (!this.details.geography) {
                this.updateForecast()
              }
            })
        })
    },

    updateForecast (triggeredFromGeo = false) {
      if (!this.mapBounds || this.loading) {
        return
      }

      const currentForecastOptions = this.getCommonForecastOptions
      const isTargetingEqual = this.forecastOptionsLastVersion
        ? _.isEqual(this.forecastOptionsLastVersion, currentForecastOptions)
        : false

      if (!isTargetingEqual) {
        this.$store.dispatch('audience/updateForecast', { ...currentForecastOptions })

        this.forecastOptionsLastVersion = JSON.parse(JSON.stringify(currentForecastOptions))
      }

      this.updateForecastInventoryAndVenues(triggeredFromGeo)
    },
    updateForecastInventoryAndVenues (triggeredFromGeo = false) {
      if (this.loading || triggeredFromGeo) {
        return
      }
      this.updateForecastInventory()
      if (this.audiencePanelTab === 1 && this.filterAsImove) {
        this.updateForecastVenues()
      }
    },

    updateForecastInventory () {
      this.$store.dispatch('audience/updateForecastInventory', this.getCommonForecastOptions)
    },

    updateForecastVenues () {
      this.$store.dispatch('audience/updateForecastVenues', this.getCommonForecastOptions)
    },

    setFocusedGeoInfo (geoTarget) {
      const storeIndex = this.selectedGeoTargets.findIndex(selectedGeoTarget => this.isSameGeoTarget(selectedGeoTarget, geoTarget))
      this.$store.commit('audience/setFocusedGeoInfo', { index: storeIndex, centerMap: false })
    },
    unsetFocusedGeoInfo () {
      this.$store.commit('audience/setFocusedGeoInfo', { index: -1, centerMap: false })
    },
    async mapBoundsUpdated (mapApiPoco) {
      this.$store.commit('audience/setMapBounds', mapApiPoco)
      this.$store.commit('audience/setMapViewPort', mapApiPoco)

      await this.updateGeoTargetsJson(true)

      const pagination = {}
      if (this.filterAsImove) { pagination.page = 1 }
      if (this.pagination.filtering) { pagination.filtering = false }
      if (Object.keys(pagination).length) {
        this.$store.commit('audience/setPagination', pagination)
      }
    },
    async updateGeoTargetsJson (disableRecentering = false) {
      if (this.updateGeoTargetsJsonCT) {
        this.updateGeoTargetsJsonCT.cancel('Request canceled by a newer request.')
        this.updateGeoTargetsJsonCT = null
      }

      const { getUpdatedGeoTargets, cancelTokenSource } = geoBoundingBoxService.getGeoTargetsJsonUpdater(this.selectedGeoTargets, this.mapViewPort, disableRecentering)
      if (!getUpdatedGeoTargets) return

      this.updateGeoTargetsJsonCT = cancelTokenSource
      try {
        this.geoTargetsWithGeoJson = await getUpdatedGeoTargets()
        this.disableRecentering = disableRecentering
      } catch (e) {} finally {
        this.updateGeoTargetsJsonCT = null
      }
    },

    saveAudience () {
      this.saving = true

      const audience = {
        bookingStatus: this.details.bookingStatus,
        name: this.$store.getters[this.getName] || this.details.name,
        externalId: this.externalId,
        budget: this.$store.getters['createCampaign/getBudget'],
        exchangeId: this.$store.getters['createCampaign/getExchange'].id,
        maxCpm: this.$store.getters['createCampaign/getMaxCpm'],
        startDate: this.$store.getters['createCampaign/getFullStartDate'],
        endDate: this.$store.getters['createCampaign/getFullEndDate'],
        id: this.ids.lineId,
        orderId: this.ids.campaignId,
        accountId: this.ids.accountId,
        targeting: this.$store.getters['audience/selectedTargets'],
        priority: this.$store.getters['createCampaign/getLinePriority'],
        usePublicExchange: this.$store.getters['audience/getUsePublicExchange']
      }

      if (this.hasMoment) {
        audience.momentId = this.$store.getters['createCampaign/momentId']
      }

      const deals = this.$store.getters['createCampaign/deals']
      audience.deals = deals.map(x => { return { code: x.code } })

      const geography = this.$store.getters['audience/geography']
      if (geography && ((geography.filters && geography.filters.length > 0) || geography.groups)) {
        audience.geography = geography
      }

      const segments = this.$store.getters['audience/getEnvironmentsApiFormat']
      if (segments && Object.keys(segments).length > 0) {
        audience.segments = segments
      } else {
        audience.segments = null
      }

      if (this.isProposalLine) {
        this.$store.commit('proposals/setChartLoading', { lineId: audience.id, isLoading: true })

        if (this.$store.getters['createCampaign/getBudget'] && this.$store.getters['audience/forecast'].budget) {
          audience.budgeted = {
            impressions: {
              from: this.$store.getters['audience/forecast'].budget.impressions.value,
              to: this.$store.getters['audience/forecast'].budget.impressions.value
            }
          }
        }
      }

      // ASSIGNMENTS & CREATIVES
      this.$store.dispatch('audience/editLine', audience)
        .then(resp => {
          if (resp.status === 200) {
            const updatedLine = resp.data

            updatedLine.geography = geography
            updatedLine.segments = segments
            updatedLine.deals = deals.map(x => { return { code: x.code, deal: x } })

            const closeCallback = audience.momentId
              ? { name: 'refreshWeatherMoment', args: updatedLine.id }
              : null

            if (this.isProposalLine) {
              updatedLine.budgeted = { impressions: { from: 0, to: 0 } }
              if (audience.budget > 0 && this.$store.getters['audience/forecast'].budget) {
                const impressions = this.$store.getters['audience/forecast'].budget.impressions.value
                updatedLine.budgeted.impressions = { from: impressions, to: impressions }
              }

              this.$store.commit('proposals/updateLine', updatedLine)

              const forecasts = {
                lineId: updatedLine.id,
                forecasts: this.$store.getters['audience/forecast'],
                forecastInventory: this.$store.getters['audience/forecastInventory']
              }
              this.$store.commit('proposals/updateLineForecasts', forecasts)

              this.$store.dispatch('proposals/saveOpenedPanelId', updatedLine.id).then(() => {
                this.$store.commit('snackbar/setSnackbar', {
                  type: 'success',
                  msg: `Line ${audience.name} has been updated`
                })

                this.close(closeCallback)
              })
            } else {
              this.$store.commit('campaignReport/setLineForecast', this.$store.getters['audience/forecast'])

              // ASSIGNMENTS & CREATIVES
              var newCreativesIds = this.newCreatives.map(c => c.id)
              var removedCreatives = this.assignedCreatives.filter(c => !newCreativesIds.includes(c.id))
              var addedCreatives = this.newCreatives.filter(c => !this.assignments.find(a => a.creativeId === c.id))

              const options = {
                accountId: this.ids.accountId,
                lineId: this.ids.lineId
              }

              var addedCreativesPromises = addedCreatives.length
                ? addedCreatives.map(creative => {
                  if (!creative.accountId) {
                    creative.accountId = this.advertiser.id
                  }
                  if (!creative.industry) {
                    creative.industry = this.$store.getters['createCampaign/getDefaultIndustry'].name
                  }
                  return this.$store.dispatch('campaignReport/assignCreative', { options, creative })
                })
                : [new Promise((resolve, reject) => {
                  resolve([])
                })]

              var removedCreativesPromises = removedCreatives.length
                ? removedCreatives.map(creative => {
                  var unassignOptions = Object.assign({}, options, {
                    orderId: this.ids.campaignId,
                    creativeId: creative.id,
                    assignmentId: creative.assignmentId
                  })

                  return this.$store.dispatch('campaignReport/unassignCreative', unassignOptions)
                })
                : [new Promise((resolve, reject) => {
                  resolve([])
                })]

              var allCreativesPromises = addedCreativesPromises.concat(removedCreativesPromises)

              Promise.all(allCreativesPromises)
                .then(allCreativesResp => {
                  this.$store.commit('snackbar/setSnackbar', {
                    type: 'success',
                    msg: `Line ${audience.name} has been updated`
                  })

                  this.$store.dispatch('campaignReport/refreshLinesDataTable')
                  this.$store.commit('campaignReport/updateLineForReport', updatedLine)

                  this.close(closeCallback)
                })
            }
          }
        })
        .catch(error => {
          let msg = error.response.data.errors[0]
          if (msg.errorCode === 'unexpected') {
            msg = 'Unexpected: ' + msg.id
          } else msg = msg.message

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
        .finally(() => {
          this.saving = false
        })
    },

    seeDetails () {
      this.openPanel('audience')

      const elem = document.querySelector('#audience-panel')
      window.scrollTo(0, elem.offsetTop)

      this.isFilterOnMoveVisible = true

      if (this.audiencePanelTab === 0) {
        this.audiencePanelTab = 1
        this.updateForecastVenues()
      }
    },
    hideDetails () {
      this.isFilterOnMoveVisible = false
    },
    updateTableToMapSync (pagination) {
      this.$store.commit('audience/setPagination', pagination)
      this.updateForecastVenues()

      if (this.pagination.filtering && !this.$vuetify.breakpoint.xsOnly) {
        this.updateForecastInventory()
      }
    },

    preClose () {
      if (this.loadingLine) {
        this.close()
        return
      }

      if (this.instance !== 'editLine') {
        this.close()
        return
      }

      const noPropertiesChanged = [
        this.$store.getters[this.getName] === this.details.name,
        this.$store.getters[this.getExternalId] === this.details.externalId
      ]

      if (!this.isOnAuctionPackagePage) {
        noPropertiesChanged.push(
          this.newCreatives.filter(c => !this.assignments.find(a => a.creativeId === c.id)).length === 0,
          this.assignments.every(a => this.newCreatives.find(c => c.id === a.creativeId && c.name === a.creative.name)),
          parseFloat(this.$store.getters['createCampaign/getBudget']) === this.details.budget,
          (this.$store.getters['createCampaign/getMaxCpm']).toFixed(2) === this.details.maxCpm.toFixed(2),
          moment(this.$store.getters['createCampaign/getFullStartDate']).isSame(this.details.startDate, 'hour'),
          moment(this.$store.getters['createCampaign/getFullEndDate']).isSame(this.details.endDate, 'hour')
        )
      }

      if (this.isOnAuctionPackagePage) {
        const feesBefore = this.details.fees || []
        const feesAfter = this.$store.getters['auctionPackage/fees'] || []
        noPropertiesChanged.push(!this.didFeesChange(feesBefore, feesAfter))
      }

      // cannot use this.data.targeting, because it's value is assigned to this.data.audienceSegments and deleted after
      // so it will always be undefined here
      const audienceSegments = this.details.audienceSegments || []
      const selectedTargets = this.$store.getters['audience/selectedTargets'] || []

      const daypartIndex = audienceSegments.findIndex(x => x.target === 'daypart')
      if (daypartIndex >= 0) audienceSegments[daypartIndex].targetValues = audienceSegments[daypartIndex].targetValues.sort((a, b) => parseInt(a) - parseInt(b))
      noPropertiesChanged.push(_.isEqual(selectedTargets, audienceSegments))

      // Priority
      noPropertiesChanged.push(this.details.priority?.id === this.$store.getters['createCampaign/getLinePriority']?.id)

      // Deals
      const deals = this.$store.getters['createCampaign/deals']
      noPropertiesChanged.push(_.isEqual(deals.map(x => x.code).sort(), this.details.deals.map(x => x.code).sort()))

      // Geography
      let geography
      if (!this.isOnAuctionPackagePage) {
        geography = this.$store.getters['audience/geography']
      } else {
        var selectedGeoTargetsNoGeoJSON = geoService.removeGeoJSON(this.selectedGeoTargets)
        geography = geoService.geoTargetsToApiFormatting(selectedGeoTargetsNoGeoJSON)
      }
      if (this.details.geography) {
        noPropertiesChanged.push(_.isEqual(geography, this.details.geography))
      }

      // Environments
      const segments = this.$store.getters['audience/getEnvironmentsApiFormat']
      if (this.details.segments && segments && Object.keys(segments).length > 0) {
        noPropertiesChanged.push(_.isEqual(segments, this.details.segments))
      }

      // Moments
      if (this.details.momentId !== this.$store.getters['createCampaign/momentId']) {
        noPropertiesChanged.push(false)
      }

      if (this.details.momentId) {
        const momentConditions = this.$store.getters['createCampaign/momentConditions']
        noPropertiesChanged.push(_.isEqual(momentConditions, this.momentInitialDefinition))
      }

      if (noPropertiesChanged.every(x => x)) this.close()
      else this.isConfirmationOpen = true
    },
    close (arg) {
      this.$emit('close', arg)

      this.isConfirmationOpen = false
      this.mapExists = true
      this.showMapOnly = false

      this.showPage = false
      this.loadingLine = true
      this.isMomentUpdated = false
      this.momentInitialDefinition = false
    },

    initializeNewLine () {
      var market = defaultExchangeValues.getDefaultValuesByCurrency(this.campaign?.currency ? this.campaign.currency : 'USD').market
      market = this.exchanges.filter(e => e.key === market)

      this.$store.commit('createCampaign/setLineName', this.marketDefaultValues.lineName)
      this.$store.commit('createCampaign/storeExchangeDetails', { exchange: market[0] })
      this.$store.commit('createCampaign/setOrderIdFromURL', this.campaign.id)

      this.openDefaultPanels()

      this.$store.commit('createCampaign/setLineName', this.namePlaceholder)
      this.$store.dispatch('createCampaign/loadOrganizationDeals', this.campaign.buyerId)
        .then(deals => {
          this.$store.commit('createCampaign/setDeals', deals)
          this.loadingLine = false
          this.updateForecast()
        })
    },

    addLine () {
      this.saving = true
      this.$store.dispatch('createCampaign/createCampaign')
        .then(id => {
          if (id) {
            const message = `Line order ${this.$store.getters['createCampaign/getLineName']} has been created`
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: message
            })

            let closeCallback
            if (this.isProposalLine) {
              tracking.sendEvent(['ga'], 'addedLineToProposal')
              closeCallback = { name: 'openNewLine' }
            } else {
              closeCallback = { name: 'refreshLinesDataTable' }
            }
            this.close(closeCallback)
          }
        })
        .catch(error => {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${error}`
          })
        })
        .finally(() => {
          this.saving = false
        })
    },

    updateValidationStatus (isValid = true, field) {
      this.validationStatus = {
        isValid: isValid,
        field: field
      }
    },
    handlePanelError (panelInfo) {
      this.openPanel(panelInfo.panelName)
      this.scrollToElement(panelInfo.panelSelector)
      this.focusElement(panelInfo.fields?.[this.validationStatus.field])
      this.updateValidationStatus()
    },
    scrollToElement (selector) {
      const element = document.querySelector(selector)
      if (element) {
        element.scrollIntoView()
      }
    },
    focusElement (selector) {
      const elementToFocus = document.querySelector(selector)
      if (elementToFocus) {
        elementToFocus.focus()
      }
    },
    openDefaultPanels () {
      const defaultPanels = ['audience']

      if (!this.isOnAuctionPackagePage) {
        defaultPanels.push('budget')
      }

      if (this.$store.getters['user/isForAdServer']) {
        defaultPanels.push('lineGoal')
        defaultPanels.push('linePriority')
      }

      this.panelsStateController = defaultPanels.map(i => this.panelsOrder.indexOf(i)).filter(i => i >= 0)
    },
    openPanel (panelName) {
      if (panelName === 'campaign') {
        this.panelsCampaignModel = 0
      }

      if (!this.isPanelOpened(panelName)) {
        const i = this.panelsOrder.indexOf(panelName)
        if (i > -1) {
          this.panelsStateController.push(i)
        }
      }
    },
    isPanelOpened (panelName) {
      const i = this.panelsOrder.indexOf(panelName)
      return i > -1
        ? this.panelsStateController.includes(i)
        : false
    },
    saveWrapper () {
      if (audienceService.needToFixMoment(this.hasMoment, this.isMomentValid)) {
        this.scrollBackToWeatherMoment()
        return
      }

      const momentEvent = audienceService.getMomentEventToEmit({
        instance: this.instance,
        line: this.data,
        momentObj: this.$store.getters['createCampaign/momentConditions'],
        initialMomentObj: this.momentInitialDefinition,
        hasMoment: this.hasMoment,
        isMomentValid: this.isMomentValid,
        isMomentUpdated: this.isMomentUpdated
      })

      if (momentEvent) {
        this.$root.$emit(momentEvent)
        return
      } else this.isMomentUpdated = true

      if (!audienceService.canByPassMomentSteps(this.hasMoment, this.isMomentValid, this.isMomentUpdated)) return

      for (const panel of this.panelsToValidate) {
        const panelInfo = campaignValidationService.getCampaignPanelInfo(panel)
        this.$root.$emit(panelInfo.validationEmit)
        if (!this.validationStatus.isValid) {
          this.handlePanelError(panelInfo)
          return
        }
      }

      if (this.isOnAuctionPackagePage) {
        this.saveAuctionPackage()
        return
      }

      this.instance === 'editLine' ? this.saveAudience() : this.addLine()
    },
    saveAuctionPackage () {
      this.saving = true
      const isScheduled = Boolean(this.$store.getters['auctionPackage/getScheduleOption'] === 'schedule')

      const data = {
        id: this.details.id,
        name: this.$store.getters['auctionPackage/getName'] || this.details.name,
        externalId: this.externalId,
        exchange: { id: this.exchange.id, key: this.exchange.key },
        targeting: this.$store.getters['audience/selectedTargets'],
        startDate: isScheduled ? this.$store.getters['createCampaign/getFullStartDate'] : null,
        endDate: isScheduled ? this.$store.getters['createCampaign/getFullEndDate'] : null,
        mergeCreativeFormatRatio: this.$store.getters['auctionPackage/getMergeCreativeFormatRatio'],
        mustUseReencodedDspCreative: this.$store.getters['auctionPackage/getMustUseReencodedDspCreative'],
        usePublicExchange: this.$store.getters['audience/getUsePublicExchange']
      }

      const dealsToSave = this.$store.getters['createCampaign/deals']
      const dealsBefore = this.details.deals.map(x => x.code)
      const dealsAfter = dealsToSave.map(x => x.code)
      if (this.didDealsChange(dealsBefore, dealsAfter)) data.deals = dealsToSave.map(x => { return { code: x.code } })

      const feesBefore = this.details.fees || []
      const feesAfter = this.$store.getters['auctionPackage/fees'] || []
      if (this.didFeesChange(feesBefore, feesAfter)) data.fees = this.$store.getters['auctionPackage/fees']

      const geography = this.$store.getters['audience/geography']
      if (geography && ((geography.filters && geography.filters.length > 0) || geography.groups)) {
        data.geography = geography
      } else {
        data.geography = null
      }

      const segments = this.$store.getters['audience/getEnvironmentsApiFormat']
      if (segments && Object.keys(segments).length > 0) {
        data.segments = segments
      } else {
        data.segments = null
      }

      if (this.hasMoment) {
        data.momentId = this.$store.getters['createCampaign/momentId']
      } else {
        data.momentId = null
      }

      this.$store.dispatch('auctionPackage/editAuctionPackage', data)
        .then(resp => {
          if (resp.status === 200) {
            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: `Auction Package ${data.name} has been updated`
            })

            const updatedData = resp.data
            const closeCallback = data.momentId
              ? { name: 'refreshWeatherMoment', args: updatedData.id }
              : true
            this.close(closeCallback)
          }
        })
        .catch(error => {
          let msg = error.response.data.errors[0]
          if (msg.errorCode === 'unexpected') {
            msg = 'Unexpected: ' + msg.id
          } else msg = msg.message

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
        .finally(() => {
          this.saving = false
        })
    },
    switchPanelAudienceTab (tab) {
      this.audiencePanelTab = tab
      this.isFilterOnMoveVisible = tab === 1
      this.updateForecastVenues()
    },

    toggleView () {
      this.mapExists = false
      setTimeout(() => {
        this.mapExists = true
        this.showMapOnly = !this.showMapOnly
      }, 70)
    },

    getDownloadFileName (scope) {
      const lineName = this.name
      if (lineName) {
        return lineName
      }

      if (this.$store.getters['user/isForAdServer']) {
        switch (scope) {
          case 'faces':
            return 'ScreensExport'
          case 'venues':
            return 'VenuesExport'
        }
      }

      return 'inventory'
    },

    downloadInventory (scope) {
      this.downloadingInventory = true

      const options = this.getCommonForecastOptions
      audienceApi.getListTargetedInventory(options, scope)
        .then(res => {
          const name = this.getDownloadFileName(scope)
          csvService.validateAndExportCsv(res, name)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.downloadingInventory = false
        })
    },

    scrollBackToWeatherMoment () {
      if (!this.panelsStateController.includes(2)) {
        this.panelsStateController.push(2)
      }

      const panelElement = document.querySelector('#moments-panel')
      const weatherElement = document.querySelector('#weather-moment')
      if (panelElement && weatherElement) {
        panelElement.scrollIntoView()
        this.$root.$emit('validateMoment')
      }
      this.saving = false
    },

    resumeSaving () {
      this.isMomentUpdated = true
      this.saveWrapper()
    },

    saveMomentInitialDefinition () {
      this.momentInitialDefinition = JSON.parse(JSON.stringify(this.$store.getters['createCampaign/momentConditions']))
    },

    momentsError (err) {
      let msg = 'Moments: '

      if (err && err.title) {
        msg += err.title
      } else {
        msg += 'Unknown error'
      }

      this.$store.commit('snackbar/setSnackbar', { type: 'error', msg })
    },

    nameChanged: _.debounce(function (name = null) {
      if (this.$store.getters['user/isForAdServer'] || name) {
        this.$store.commit(this.setName, name)
      }
    }, 300),
    externalIdChanged: _.debounce(function (externalId = null) {
      if (externalId || externalId.length === 0) {
        this.$store.commit(this.setExternalId, externalId)
      }
    }, 300),

    didFeesChange (feesBefore, feesAfter) {
      if (feesBefore.length !== feesAfter.length) return true

      for (let i = 0; i < feesBefore.length; i++) {
        if (Object.keys(feesBefore[i]).filter(k => k !== 'id').some(k => feesBefore[i][k] !== feesAfter[i][k])) return true
      }

      return false
    },
    didDealsChange (dealsBefore, dealsAfter) {
      if (dealsBefore.length !== dealsAfter.length) return true

      return !dealsBefore.every((element, index) => element === dealsAfter[index])
    },
    convertGeoTargetToMapFormat (geoTarget) {
      return {
        isIncluded: geoTarget.isIncluded,
        radius: geoTarget.radius,
        isUnrendered: geoTarget.isOutsideOfViewPort,
        geolocation: {
          label: geoTarget.label,
          value: geoTarget.value,
          type: geoTarget.type,
          geography: {
            latitude: geoTarget.geography?.latitude,
            longitude: geoTarget.geography?.longitude,
            geoJSON: geoTarget.geography?.geoJSON
          }
        }
      }
    },
    convertGeoTargetToStoreFormat (geoTarget) {
      return {
        geography: geoTarget.geolocation.geography,
        isIncluded: geoTarget.isIncluded,
        label: geoTarget.geolocation.label,
        radius: geoTarget.radius,
        type: geoTarget.geolocation.type,
        value: geoTarget.geolocation.value
      }
    },
    isSameGeoTarget (geoTargetFromStore, geoTargetFromMap) {
      return geoTargetFromStore.value === geoTargetFromMap.geolocation.value &&
        geoTargetFromStore.label === geoTargetFromMap.geolocation.label &&
        geoTargetFromStore.radius === geoTargetFromMap.radius &&
        geoTargetFromStore.isIncluded === geoTargetFromMap.isIncluded
    },
    geoTargetAdded (geoTarget) {
      this.geoTargetUpdatedFromMap = true
      const obj = this.convertGeoTargetToStoreFormat(geoTarget)
      this.$store.dispatch('audience/updateGeoTargetsAction', [...this.selectedGeoTargets, obj])
      this.updateForecast()
    },
    geoTargetUpdated (geoTarget, mapIndex) {
      this.geoTargetUpdatedFromMap = true
      const initialUpdatedGeoTarget = this.mapGeoTargets[mapIndex]
      const storeIndex = this.selectedGeoTargets.findIndex(selectedGeoTarget => this.isSameGeoTarget(selectedGeoTarget, initialUpdatedGeoTarget))
      const updatedGeoTargets = this.selectedGeoTargets
      updatedGeoTargets[storeIndex] = this.convertGeoTargetToStoreFormat(geoTarget)

      this.$store.dispatch('audience/updateGeoTargetsAction', updatedGeoTargets)
      this.updateForecast()
    },
    geoTargetRemoved (geoTarget) {
      this.geoTargetUpdatedFromMap = true
      const storeIndex = this.selectedGeoTargets.findIndex(selectedGeoTarget => this.isSameGeoTarget(selectedGeoTarget, geoTarget))
      const copy = [...this.selectedGeoTargets]
      copy.splice(storeIndex, 1)
      this.$store.dispatch('audience/updateGeoTargetsAction', copy)
      this.updateForecast()
    },
    getVenueDetails (venueId) {
      return venuesAPI.getVenueDetails(venueId)
    },
    filterOnMoveChecked (isChecked) {
      this.$store.commit('audience/setFilterAsImove', isChecked)
    },
    updateVenueInclusion (venue) {
      this.$store.dispatch('audience/updateVenueInclusion', venue)
    },
    async resolveGeoJson (geographyId, geoJSONFileUrl) {
      return await geoService.resolveGeoJson(geographyId, geoJSONFileUrl)
    }
  }
}
</script>

<style scoped lang="stylus">
.paddingXless >>> .v-expansion-panel-content__wrap
  padding-left: 0px
  padding-right: 0px
</style>
