<template lang='pug'>
div
  .pa-4
    strong.pl-2 Private Deals
    v-row.d-flex.align-center.pa-2
      v-col
        v-combobox#search-deals(
          clearable
          multiple
          no-filter
          ref='searchDeals'
          label='Enter one or multiple Deal ID'
          item-text='name'
          :items='searchItems'
          :loading='searching || dealsLoading'
          :search-input.sync='search'
          :value='selectedDeals'
          :attach='true'
          :menu-props='{ contentClass: "search-deals-results", minWidth: "min-content" }'
          @click:clear='clearSearch'
          @blur='clearSearch'
          @change='dealAdded')
      v-col(v-if='canSeeBrowseDeal')
        span.pa-4 or &nbsp;
        a#browse-deals-btn.text-uppercase.font-weight-bold(@click='toggleLoadDealsDialog(!isDealDialogOpen)') Browse deals
  v-simple-table
    thead
      tr
        th Deal ID
        th Publisher
        th Deal Name
        th Price
        th
    tbody
      tr(v-if='dealsLoading')
        td(colspan='100%' align='center')
          span Loading deals...

      tr(v-if='!dealsLoading && !deals.length')
        td(colspan='100%' align='center') No deals found

      tr(v-for='deal in deals' @mouseover='dealRowHover(deal)' @mouseleave='dealRowLeave(deal)')
        td
          v-chip(label small)
            v-icon.mr-1(small :color='deal.status === "Active" ? "primary" : "grey"') mdi-tag
            strong {{ deal.code }}

        td(v-if='!deal.source' colspan='2')
          .grey--text Deal not recognized

        td(v-if='deal.source')
          | {{ deal.source.key }}

        td(v-if='deal.source')
          | {{ deal.name }}
          .text-caption.grey--text {{ deal.startDate | date }} - {{ deal.endDate | date }}
        td
          span.deal-price(v-if='deal.floorCPM && !canSeeMarketplaceTabAsDspPartner()') {{ deal.floorCPM | currency }} CPM
          span.deal-price(v-if='deal.floorCPMs && canSeeMarketplaceTabAsDspPartner()') {{ getFloorCPMsLabel(deal.floorCPMs) }} CPM
          .text-caption.grey--text(v-if='deal.auctionType') {{ deal.auctionType.replace('_', ' ') }}
        td.text-right
          v-btn.remove-deal(icon small @click='dealRemoved(deal)')
            v-icon(v-if='isDealHovered(deal)') mdi-close
</template>

<script>
import audienceApi from '@/services/audience.api'
import helperService from '@/services/helpers.service'
import trackingService from '@/services/tracking'
import dealService from '@/services/deal.service'
import flags from '@/plugins/rox/flags'

import { debounce } from 'lodash'

export default {
  props: {
    deals: {
      type: Array,
      default: () => []
    },
    dealsLoading: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      search: null,
      searching: false,
      searchResults: [],
      selectedDeals: [],
      dealHovered: null,
      isDealDialogOpen: false,
      canSeeBrowseDeal: false,
      canSeeNewUi: false
    }
  },
  created: function () {
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
    this.canSeeBrowseDeal = this.$flags.canSeeBrowseDeal.isEnabled()
  },
  watch: {
    search: debounce(function (newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        const multiple = newVal.includes(',') || newVal.includes(' ') || newVal.includes('\t')
        if (!multiple) {
          this.searchDeals(newVal)
          trackingService.sendEvent(['ga'], 'searchedDeal', { label: newVal })
        }
      }
    }, 300)
  },
  computed: {
    searchItems () {
      return this.searchResults.filter(r => this.deals.findIndex(d => d.code === r.code) === -1)
    }
  },
  methods: {
    searchDeals (q) {
      this.searching = true

      const pagination = {
        page: 1,
        itemsPerPage: 5,
        sortBy: ['name'],
        sortDesc: [false]
      }

      return audienceApi.getDeals(pagination, { search: q })
        .then(res => {
          this.searchResults = res
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.searching = false
        })
    },
    dealAdded (deals) {
      this.searching = true

      const dealPromises = deals
        .map(deal => {
          return typeof deal === 'string'
            ? deal.split(/\r|;\s*|,\s*|\n|\s+/g)
            : deal
        })
        .flat()
        .filter(deal => !!deal)
        .map(deal => {
          return typeof deal === 'string'
            ? this.getDeal(deal)
            : new Promise(resolve => {
              resolve(deal)
            })
        })

      Promise.all(dealPromises)
        .then(fullDeals => {
          fullDeals.map(fullDeal => {
            this.$emit('dealAdded', fullDeal)
            trackingService.sendEvent(['ga'], 'addedDeal', { label: fullDeal.code })
          })

          this.selectedDeals = []
        })
        .catch(e => {
          console.log(e)
          this.selectedDeals = []
        })
        .finally(() => {
          this.searching = false
          this.search = null
        })
    },
    dealRemoved (deal) {
      this.$emit('dealRemoved', deal)
      trackingService.sendEvent(['ga'], 'removedDeal', { label: deal.code })
    },
    toggleLoadDealsDialog () {
      this.$root.$emit('toggleLoadDealsDialog')
    },
    getDeal (dealCode) {
      return audienceApi.getDeal(dealCode)
        .then(deal => deal)
        .catch(err => {
          if (err.response.status === 404) {
            return helperService.generateUnknownDealObj(dealCode)
          }
        })
    },
    clearSearch () {
      this.search = null
      this.searchResults = []
    },
    dealRowHover (deal) {
      this.dealHovered = deal.code
    },
    dealRowLeave (deal) {
      this.dealHovered = null
    },
    isDealHovered (deal) {
      return this.dealHovered === deal.code
    },
    getFloorCPMsLabel: dealService.getFloorCPMsLabel,
    canSeeMarketplaceTabAsDspPartner: () => flags.canSeeMarketplaceTabAsDspPartner.isEnabled()
  }
}
</script>
