import geoService from '@/services/geo.service'
import auctionPackageApi from '@/services/auctionPackage.api'

function momentsDefault () {
  return {
    weather: null,
    finance: {
      stock: null,
      currency: null,
      crypto: null,
      commodity: null,
      index: null
    }
  }
}

function createAuctionPackageStoreDefaults () {
  return {
    auctionPackage: {
      id: '',
      name: '',
      externalId: '',
      tokens: [],
      mergeCreativeFormatRatio: true,
      mustUseReencodedDspCreative: true
    },
    exchange: { key: '' },
    creativeCount: 0,
    isMomentValid: null,
    momentConditions: momentsDefault(),
    momentId: null,
    momentCreated: false,
    fees: [],
    advertiser: null,
    scheduleOption: 'always'
  }
}

export default {
  namespaced: true,
  state: createAuctionPackageStoreDefaults(),
  getters: {
    getCreativeCount: state => {
      return state.creativeCount
    },
    getName: state => {
      return state.auctionPackage.name
    },
    getExternalId: state => {
      return state.auctionPackage.externalId
    },
    getExchange: state => {
      return state.exchange
    },
    getAdvertiser: state => {
      return state.advertiser
    },
    getLoadingToken: state => {
      return state.loadingToken
    },
    getScheduleOption: state => {
      return state.scheduleOption
    },
    getMergeCreativeFormatRatio: state => {
      return state.auctionPackage.mergeCreativeFormatRatio
    },
    getMustUseReencodedDspCreative: state => {
      return state.auctionPackage.mustUseReencodedDspCreative
    },
    shareLink: state => {
      const id = state.auctionPackage.id
      const tokens = state.auctionPackage.tokens
      return tokens.length ? `${window.location.origin}/auction-packages/${id}?token=${tokens[0]}` : 'Loading'
    },
    getCommonForecastOptions: (state, getters, rootState, rootGetters) => {
      const organizationId = rootGetters['user/getProfile'].organizationId

      var endDate = new Date()
      endDate.setDate(endDate.getDate() + 7)

      const options = {
        mapId: 'create-campaign',
        buyerId: organizationId,
        startDate: new Date().toISOString(),
        endDate: endDate.toISOString(),
        exchange: getters.getExchange.key,
        targeting: rootGetters['audience/selectedTargets'],
        usePublicExchange: rootGetters['audience/getUsePublicExchange'],
        deals: rootGetters['createCampaign/dealIds'],
        percentiles: [1, 100]
      }

      const geography = rootGetters['audience/geography']
      if (geography && Object.keys(geography).length > 0) {
        // do NOT send geoJSON(s) to Forecast for performance
        // easier to go through "selectedGeoTargets" and re-format
        // than trying to go through already formated "geography"
        var selectedGeoTargetsNoGeoJSON = geoService.removeGeoJSON(rootGetters['audience/selectedGeoTargets'])
        options.geography = geoService.geoTargetsToApiFormatting(selectedGeoTargetsNoGeoJSON)
      }

      const environmentsApiFormat = rootGetters['audience/getEnvironmentsForecastApiFormat']
      if (environmentsApiFormat.groups && environmentsApiFormat.groups.length > 0) {
        options.segments = environmentsApiFormat
      }

      return options
    },
    momentId: state => {
      return state.momentId
    },
    fees: state => state.fees
  },
  mutations: {
    storeAuctionPackageDetails (state, obj) {
      state.auctionPackage = obj
    },
    storeExchangeDetails (state, obj) {
      state.exchange = obj.exchange
    },
    resetAuctionPackage (state) {
      Object.assign(state, createAuctionPackageStoreDefaults())
    },
    setName (state, val) {
      state.auctionPackage.name = val
    },
    setScheduleOption (state, val) {
      state.scheduleOption = val
    },
    setAdvertiser (state, val) {
      state.advertiser = val
    },
    setExternalId (state, val) {
      state.auctionPackage.externalId = val
    },
    setFees (state, newFees) {
      state.fees = newFees
    },
    setShareToken (state, tokenObj) {
      if (tokenObj) {
        state.auctionPackage.tokens.push(tokenObj)
      }
    },
    setLoadingToken (state, bool) {
      state.loadingToken = bool
    },
    setAuctionPackageId (state, id) {
      state.auctionPackage.id = id
    },
    setCreativeCount (state, count) {
      state.creativeCount = count
    },
    setMergeCreativeFormatRatio (state, bool) {
      state.auctionPackage.mergeCreativeFormatRatio = bool
    },
    setMustUseReencodedDspCreative (state, bool) {
      state.auctionPackage.mustUseReencodedDspCreative = bool
    },
    setMomentId (state, val) {
      state.momentId = val
    }
  },
  actions: {
    async generateOrGetToken ({ state, commit }) {
      if (!state.auctionPackage.tokens.length) {
        commit('setLoadingToken', true)

        try {
          var data = await auctionPackageApi.getToken(state.auctionPackage.id)
          commit('setShareToken', data.token)
          commit('setLoadingToken', false)
        } catch (err) {
          data = await auctionPackageApi.generateToken(state.auctionPackage.id)
          commit('setShareToken', data.token)
          commit('setLoadingToken', false)
        }
      }
    },
    editAuctionPackage ({ commit }, data) {
      return auctionPackageApi.editAuctionPackage(data.id, data)
    },
    duplicate ({ state, getters, rootState }, { auctionPackageId, organizationId }) {
      return auctionPackageApi.duplicateAuctionPackage(auctionPackageId, organizationId)
    },
    async getAssignmentsCount ({ commit, getters, dispatch }, auctionPackageId) {
      auctionPackageApi.getAssignmentsCount([], auctionPackageId)
        .then(count => {
          commit('setCreativeCount', count)
        })
    },
    createAuctionPackage ({ state, getters, rootState, rootGetters }, hasMoment) {
      const isScheduled = Boolean(state.scheduleOption === 'schedule')
      var auctionPackage = {
        exchange: {
          id: getters.getExchange.id,
          key: getters.getExchange.key
        },
        name: getters.getName,
        externalId: getters.getExternalId,
        startDate: isScheduled ? rootGetters['createCampaign/getFullStartDate'] : null,
        endDate: isScheduled ? rootGetters['createCampaign/getFullEndDate'] : null,
        targeting: rootState.audience.targeting,
        status: isScheduled ? 'Active' : 'Inactive',
        mergeCreativeFormatRatio: getters.getMergeCreativeFormatRatio,
        mustUseReencodedDspCreative: getters.getMustUseReencodedDspCreative,
        usePublicExchange: typeof rootGetters['audience/getUsePublicExchange'] === 'boolean' ? rootGetters['audience/getUsePublicExchange'] : true
      }

      const dealIds = rootGetters['createCampaign/dealIds']
      if (dealIds.length) {
        auctionPackage.deals = dealIds.map(x => {
          return { code: x }
        })
      }

      if (hasMoment && rootState.createCampaign.momentId) {
        auctionPackage.momentId = rootState.createCampaign.momentId
      }

      const geography = rootState.audience.geography
      if (geography && Object.keys(geography).length > 0) {
        auctionPackage.geography = geography
      }

      const segments = rootState.audience.segments
      if (segments.groups && segments.groups.length > 0) {
        auctionPackage.segments = segments
      }

      if (state.fees.length) auctionPackage.fees = state.fees

      return auctionPackageApi.createAuctionPackage(auctionPackage)
        .then(createdAuctionPackage => {
          if (!createdAuctionPackage.geography && auctionPackage.geography) {
            createdAuctionPackage.geography = auctionPackage.geography
          }

          return createdAuctionPackage
        }).then(auctionPackage => {
          if (!auctionPackage.id) {
            throw new Error('Auction package not created.')
          }
          return auctionPackage
        })
    }
  }
}
