import RoxBrowser from 'rox-browser'

const flags = {
  canSeeUiRebrand: new RoxBrowser.Flag(),
  canSeeBrowseDeal: new RoxBrowser.Flag(),
  canSeeReportApiSection: new RoxBrowser.Flag(),
  canSeeAuctionPackage: new RoxBrowser.Flag(),
  canSeeAuctionPackageMenu: new RoxBrowser.Flag(),
  canSeeDemographics: new RoxBrowser.Flag(),
  canSeeDspPartnerDashboard: new RoxBrowser.Flag(),
  canSeeMarketplaceTabAsDspPartner: new RoxBrowser.Flag(),
  canSeeAuctionPackagePrivateDealsTargetedImpressions: new RoxBrowser.Flag(),
  isPlanningDataIngestionEnabled: new RoxBrowser.Flag(),
  isSelfServePlanningEnabled: new RoxBrowser.Flag(),
  canBulkUploadScreens: new RoxBrowser.Flag(),
  cityZoomBreakpoint: new RoxBrowser.Flag(),
  postalCodeZoomBreakpoint: new RoxBrowser.Flag(),
  isShapeLoadedByMapViewportEnabled: new RoxBrowser.Flag(),
  canSeeGlobalExchange: new RoxBrowser.Flag()
}

export default flags
