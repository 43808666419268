<template lang='pug'>
div
  v-row.mb-0.ml-2.mt-4(align='center')
    v-item-group(mandatory @change='creativeTypesFilterChanged' :value='creativeTypeFilter')
      v-item(v-for='creativeType in creativeTypes' :key='creativeType' v-slot='{ active, toggle }' :value='creativeType')
        v-chip.mx-1(
          small
          :dark='active'
          :color="active ? 'primary' : 'blue-grey lighten-5'"
          :input-value='active'
          @click='toggle'
        )
          .text-uppercase {{ creativeType }}

  v-simple-table.my-4(dense)
    template(v-slot:default='')
      thead
        tr
          th Width / Height
          th(v-if='showAssigned') Assigned
          th.pl-0 Screens Count
          th.pl-0
            span(v-if='creativeTypeFilter === "video"') Duration
      tbody
        tr(v-for='format in groupedCreativesFormats' :key='format.key')
          td.pt-1(style='width:120px; vertical-align:top;') {{ format.minWidth + ' x ' + format.minHeight }}

          td(style='width:80px;' v-if='showAssigned')
            ul
              li(v-for='duration in format.durations' :class='duration.id')
                v-chip.text-uppercase(text-color='secondary' small outlined :color='assignedColor(format, duration)')
                  | {{ assignedCount(format, duration) }}

          td.pr-0.pl-0(style='width:100px;')
            ul
              li.d-flex.align-center(v-for='duration in format.durations' :class='duration.id')
                v-icon.mr-1.text--primary(small) mdi-television
                | {{ formatNumber(duration.numberOfScreens) }}

          td.pl-0
            ul(v-if='creativeTypeFilter === "video"')
              li.d-flex.align-center(v-for='duration in format.durations' :class='duration.id') {{ creativeTypeFullLabel(duration) }}
</template>

<style scoped>
ul {
  list-style-type: none;
  padding-left: 0px;
}
li {
  margin-top:2px;
  margin-bottom:2px;
  height: 24px;
}
.v-chip.v-size--small {
  height:20px;
}
 </style>

<script>
import helpersService from '@/services/helpers.service'

export default {
  name: 'creativesFormatsTable',
  props: {
    creativesFormats: {
      type: Array,
      default: () => []
    },
    assignedCreatives: {
      type: Array,
      default: () => []
    },
    showAssigned: {
      type: Boolean,
      default: () => true
    }
  },
  data () {
    return {
      creativeTypeFilter: 'image',
      creativeTypes: ['image', 'video']
    }
  },
  computed: {
    groupedCreativesFormats () {
      const creativesFormatsCopy = this.creativesFormats
        .map(cf => {
          // default missing creativeTypes to "image"
          const creativeTypes = cf.creativeTypes && cf.creativeTypes.length
            ? cf.creativeTypes
            : ['image']

          // default missing durations -- should FE do this or BE ?
          const newFields = {
            minAdDuration: cf.minAdDuration
              ? cf.minAdDuration < 5 ? 5 : cf.minAdDuration
              : 5,
            maxAdDuration: cf.maxAdDuration
              ? cf.maxAdDuration > 60 ? 60 : cf.maxAdDuration
              : 60,
            creativeTypes
          }
          return Object.assign({}, cf, newFields)
        })
        .reduce((accumulator, currentValue) => {
          if (currentValue.creativeTypes.includes(this.creativeTypeFilter)) {
            // for "video", keep a list of all durations
            const currentDuration = {
              id: 'creative-format-' + currentValue.key + '-' + currentValue.minAdDuration + '-' + currentValue.maxAdDuration,
              min: currentValue.minAdDuration,
              max: currentValue.maxAdDuration,
              numberOfScreens: currentValue.numberOfScreens
            }

            // group same formats together (1 row)
            const existing = accumulator.find(a => a.key === currentValue.key)
            if (existing) {
              if (this.creativeTypeFilter === 'video') {
                // TMP UNTIL API SET [5, 60] limits to durations
                const duration = existing.durations.find(d => d.min === currentDuration.min && d.max === currentDuration.max)
                if (duration) {
                  duration.numberOfScreens += currentValue.numberOfScreens
                } else {
                  existing.durations.push(currentDuration)
                }

                // existing.durations.push(currentDuration)
              } else {
                // for "image", we don't care about "duration"
                const duration = existing.durations[0]
                duration.numberOfScreens += currentValue.numberOfScreens
              }
            } else {
              const currentValueWithDurations = Object.assign({}, currentValue, { durations: [currentDuration] })
              accumulator.push(currentValueWithDurations)
            }
          }

          return accumulator
        }, [])

      return creativesFormatsCopy.sort(this.orderCreativesFormats)
    }
  },
  watch: {
    assignedCreatives (newV) {
      const types = [...new Set(this.assignedCreatives.map(x => x.type.toLowerCase()))]
      if (types.length === 1) this.creativeTypeFilter = types[0]
    }
  },
  methods: {
    orderCreativesFormats (a, b) {
      // order by numberOfScreens DESC
      // const keyName = 'numberOfScreens'
      const keyName = 'key'

      if (a[keyName] < b[keyName]) { return 1 }
      if (a[keyName] > b[keyName]) { return -1 }
      return 0
    },
    assignedCount (creativeFormat, duration) {
      const assignedFiltered = this.assignedCreatives
        .filter(assignedCreative => {
          const isValidCreativeType = this.creativeTypeFilter === 'image'
            ? this.creativeTypeFilter === assignedCreative.type.toLowerCase()
            : creativeFormat.creativeTypes.includes(assignedCreative.type.toLowerCase())

          return assignedCreative.creativeFormat.key === creativeFormat.key &&
          this.validateDuration(assignedCreative, duration) &&
          isValidCreativeType
        }
        )
      return assignedFiltered.length
    },
    validateDuration (assignedCreative, duration) {
      const isImage = assignedCreative.type.toLowerCase() === 'image'
      return isImage
        ? true
        : assignedCreative.duration >= duration.min &&
          assignedCreative.duration <= duration.max
    },
    assignedColor (creativeFormat, duration) {
      return this.assignedCount(creativeFormat, duration)
        ? 'success'
        : 'error'
    },
    assignedIcon (item) {
      return this.assignedCount(item)
        ? 'mdi-check'
        : 'mdi-close'
    },
    formatNumber (nbr) {
      return helpersService.shortenNumber(nbr)
    },
    creativeTypeFullLabel (duration) {
      const label = duration.min === duration.max
        ? duration.min + ' sec only'
        : duration.min + '-' + duration.max + ' sec'
      return label
    },
    creativeTypesFilterChanged (creativeType) {
      this.creativeTypeFilter = creativeType
    }
  }
}
</script>
