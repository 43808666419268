<template lang="pug">
div
  v-card.pt-3(color="transparent" flat)
    vue-headful(:title="componentConfig.branding.title('Auction Package Overview')")
    v-toolbar#auction-package-title.elevation-0(color="transparent")
      .text-h5.hidden-sm-and-down.text-capitalize Auction Packages

      v-text-field#auction-package-find-field(:label='"Find Auction Packages"' prepend-inner-icon='mdi-magnify' solo hide-details v-model='search' clearable :class="{'mx-4': $vuetify.breakpoint.smAndUp }")
      v-btn#new-auction-package-btn.mt-0.hidden-sm-and-down(large rounded color='primary' router-link :to='getCreateAuctionPackagePath()')
        v-icon(left) mdi-plus
        span New Auction Package

      v-tabs#auction-packages-tabs(background-color='transparent' slider-color='primary' slot='extension' :value='currentTabIdx')
        v-tab(ripple color='primary lighten-4' v-for='(tab, i) in tabs' :key='i' @change='changeTab(i)' :id='tab')
          | {{ tab }}

        confirmationDialog.modify-columns-dialog(
        @confirmed="saveColumns"
        ref="confirmationDialogColumns"
        :details="{title: `Modify Columns`, btnLabel: `Save Columns`}"
        )
          template(v-slot:customActivator='')
            v-tooltip(top)
              template(v-slot:activator='{ on }')
                v-btn.modify-columns-btn(v-on='on' icon offset-y @click="$root.$emit('openConfirmDialog', `Modify Columns`)")
                  v-icon mdi-view-column
              span.caption Modify Columns

          template(v-slot:customOptions='')
            sortableItemList.sortable-item-list(:sortableItems='sortableColumns' :onChange='onColumnsChange')

    v-divider
    div(style='background-color: #FFFFFF; overflow:auto;')
      div.d-flex.justify-space-between.ml-2.my-1
        div.d-flex.justify-start
          v-icon.mr-1.ml-3(left style='position:static;') mdi-filter
          filtersView.mt-2.filters-view(
            :addedFilters='addedFilters'
            @updatedFilter='updateFilter'
            @deletedFilter='deleteFilter'
            @clearedFilter='clearFilter'
            :toLabelMethod='filterToLabel'
            :searchMethod='filterSearch'
            )
          filterSet.filter-set(
          :filterOptions='filterOptions'
          @addedFilter='addFilter'
          :searchMethod='filterSearch'
          )
            template(v-slot:opener="{}")
              v-btn.my-0_5(color='primary' text)
                | Add Filter
        v-tooltip(top)
          template(v-slot:activator='{ on }')
            v-btn.mr-2.my-0_5(v-on='on' @click='clearFilter()' color='primary' icon)
              v-icon mdi-close
          span.caption Clear filters
    v-divider
    v-scroll-y-reverse-transition
      v-toolbar(color='secondary lighten-1' dark dense flat width='100%' :class="{'toolbar-touch': $vuetify.breakpoint.smAndDown, 'toolbar-desktop': $vuetify.breakpoint.mdAndUp }" v-show='selected.length')
        span.hidden-sm-and-down
          v-toolbar-title.text-subtitle-1(v-if='selected.length < data.length') {{ selected.length }} auction package(s) selected
          v-toolbar-title.text-subtitle-1(v-if='!selectAll && selected.length === data.length && data.length < totalItems') The {{ data.length }} auction package(s) on this page are selected.
          v-toolbar-title.text-subtitle-1(v-if='selectAll || selected.length === totalItems') All {{ totalItems }} auction packages are selected.

        span.hidden-md-and-up
          v-chip.ma-1(v-if='data.length < totalItems && !selectAll' outlined color='white' small)
            | {{ selected.length }}
          v-chip.ma-1(v-if='selected.length === totalItems || selectAll' dark color='white' class="secondary--text" small)
            | {{ totalItems }}

        v-btn.ml-2(text outlined small dark v-if='!selectAll && selected.length === data.length && data.length < totalItems' @click='selectAll = true') Select all
          span.hidden-sm-and-down
            b &nbsp;{{ totalItems }}&nbsp;
            | Auction Packages

        v-toolbar-items.ml-3
          v-btn#auction-package-export-btn.mx-2(:icon="$vuetify.breakpoint.smAndDown" :text="$vuetify.breakpoint.mdAndUp" @click="exportCsv()")
            v-icon(:small="$vuetify.breakpoint.mdAndUp") mdi-download
            span.ml-2.hidden-sm-and-down Export CSV
          actionConfirmationDialog(v-if='!selectAll' ref='confirmationDialogDuplicate' @confirmAction='duplicateAuctionPackage' type='auction package' action='duplicate' :numberOfEntries='selected.length')
          v-menu(v-if='$vuetify.breakpoint.xsOnly' :close-on-content-click='false' v-model='mobileMenuOpen')
            template(v-slot:activator='{ on }')
              v-btn(icon v-on='on' offset-y)
                v-icon mdi-dots-vertical
            v-list
              v-list-item
                actionConfirmationDialog(
                  @confirmAction='archiveAuctionPackages'
                  @closed='mobileMenuOpen = false'
                  type="auction package"
                  ref="confirmationDialogArchive"
                  :action="currentTab === 'Archived' ? 'restore' : 'archive'"
                  :numberOfEntries='selected.length'
                  :textButtonOnly='true'
                  )
        actionConfirmationDialog(
          v-if='$vuetify.breakpoint.smAndUp'
          @confirmAction='archiveAuctionPackages'
          type="auction package"
          ref="confirmationDialogArchive"
          :action="currentTab === 'Archived' ? 'restore' : 'archive'"
          :numberOfEntries='selected.length'
          )

        v-spacer
        v-btn.ml-3(icon @click="selected = []")
            v-icon mdi-close

    v-data-table.cs-data-table(
      :loading='tableLoading'
      :headers='headers'
      :items='data'
      :server-items-length='totalItems'
      :options.sync='pagination'
      v-model='selected'
      show-select
      :dense="$vuetify.breakpoint.xsOnly"
      :class="{'colorTransparent': $vuetify.breakpoint.xsOnly}"
      must-sort
      :no-data-text='noDataText'
      :footer-props="{itemsPerPageOptions: [50, 100, 250]}"
    )

      template.text-left(v-slot:item="{ item, isSelected, select }" v-if='$vuetify.breakpoint.xsOnly')
        v-card.ma-3.px-3.py-1
          v-layout.my-2
            v-flex.text-left.d-flex(xs8)
              v-checkbox.mt-0.pt-0(@click.stop="isSelected? select(false) : select(true)" :value="isSelected" hide-details color='primary')
              router-link.info--text.text-left(:to="getSingleViewPath(item.id)")
                span.text-body-1(style='word-break: break-word;') {{ item.name }}
            v-flex.text-right(xs4)
              .text-caption.grey--text
                | {{ item.lastUpdatedOn | date('MMM Do, YYYY') }}
          .my-2
            v-icon.text--secondary(medium) mdi-account-multiple
            |  {{ item.estimatedImpressions | numberWithCommas }}
            v-icon.text--secondary.ml-3(medium) mdi-map-marker
            | {{ item.estimatedNumberOfVenues | numberWithCommas }}
            v-icon.text--secondary.ml-3.mr-1(medium) mdi-television
            | {{ item.estimatedNumberOfFaces | numberWithCommas }}

      template(v-slot:item.status="{ item }")
        .d-flex.align-center
          v-switch.auction-package-status-switch.xs-small.py-0.my-0.ml-0(:input-value="item.status==='Active'" :disabled='isStatusLoading==item.id || item.status == "Archived"' hide-details color='primary' @change="updateStatus(item.name, item.id, item.status === 'Active' ? 'Inactive' : 'Active', item.exchange.id)")

      template(v-slot:item.organization="{ item }")
        .d-flex.align-center
          span.auction-package-organization  {{ item.organization.name | capitalize }}

      template(v-slot:item.ownerEmail="{ item }")
        .d-flex.align-center
          span.auction-package-owner-email  {{ item.ownerEmail }}

      template(v-slot:item.exchange="{ item }")
        span.pl-2.auction-package-market {{ marketLabel(item) }}

      template(v-slot:item.name="{ item }")
        router-link.info--text.auction-package-name.text-left(width='300px' :to="getSingleViewPath(item.id)") {{ item.name }}

      template(v-slot:item.externalId="{ item }")
        span.auction-package-external-id {{ item.externalId }}

      template(v-slot:item.code="{ item }")
        span.auction-package-code {{ item.code }}

      template(v-slot:item.estimatedImpressions="{ item }")
        .d-flex.align-center
          v-icon.text--secondary.mr-1(small) mdi-account-multiple
          span.auction-package-impressions {{ item.estimatedImpressions | numberWithCommas }}

      template(v-slot:item.estimatedNumberOfVenues="{ item }")
        .d-flex.align-center
          v-icon.text--secondary(small) mdi-map-marker
          span.auction-package-number-venues {{ item.estimatedNumberOfVenues | numberWithCommas }}

      template(v-slot:item.estimatedNumberOfFaces="{ item }")
        .d-flex.align-center
          v-icon.text--secondary.mr-1(small) mdi-television
          span.auction-package-number-faces {{ item.estimatedNumberOfFaces | numberWithCommas }}

      template(v-slot:item.targetedSuppliers="{ item }")
        span.auction-package-creative-types {{ item.targetedSuppliers ? item.targetedSuppliers.replaceAll(',', ', ') :  '' }}

      template(v-slot:item.targetedCreativeFormat="{ item }")
        span.auction-package-creative-formats {{ item.targetedCreativeFormat ? item.targetedCreativeFormat.replaceAll(',', ', ') :  '' }}

      template(v-slot:item.targetedCreativeType="{ item }")
        span.auction-package-creative-types {{ item.targetedCreativeType ? item.targetedCreativeType.replaceAll(',', ', ') :  '' }}

      template(v-slot:item.startDate="{ item }")
        div.auction-package-start-date {{ formatDate(item.startDate).date }}
        div.auction-package-start-time.caption.grey--text {{ formatDate(item.startDate).time }}

      template(v-slot:item.endDate="{ item }")
        div.auction-package-end-date {{ formatDate(item.endDate).date }}
        div.auction-package-end-time.caption.grey--text {{ formatDate(item.endDate).time }}

      template(v-slot:item.floorPrice="{ item }")
        span.auction-package-floor-price {{ item.floorPrice ? item.floorPrice : 0 | currency  }}

      template(v-slot:item.minBidRange="{ item }")
        span.auction-package-min-bid-range {{ item.floorPrice ? item.floorPrice : 0 | currency  }}

      template(v-slot:item.maxBidRange="{ item }")
        span.auction-package-max-bid-range {{ item.ceilingPrice ? item.ceilingPrice : 0 | currency  }}

      template(v-slot:item.fee1Label="{ item }")
        span.auction-package-fee-label#fee-label-1 {{ getFeeFromAuctionPackage(item, 1).label }}
      template(v-slot:item.fee1Value="{ item }")
        span.auction-package-fee-value#fee-value-1 {{ getFeeValueDisplay(getFeeFromAuctionPackage(item, 1).value) }}

      template(v-slot:item.fee2Label="{ item }")
        span.auction-package-fee-label#fee-label-2  {{ getFeeFromAuctionPackage(item, 2).label }}
      template(v-slot:item.fee2Value="{ item }")
        span.auction-package-fee-value#fee-value-2  {{ getFeeValueDisplay(getFeeFromAuctionPackage(item, 2).value) }}

      template(v-slot:item.fee3Label="{ item }")
        span.auction-package-fee-label#fee-label-3 {{ getFeeFromAuctionPackage(item, 3).label }}
      template(v-slot:item.fee3Value="{ item }")
        span.auction-package-fee-value#fee-value-3 {{ getFeeValueDisplay(getFeeFromAuctionPackage(item, 3).value) }}

      template(v-slot:item.fee4Label="{ item }")
        span.auction-package-fee-label#fee-label-4  {{ getFeeFromAuctionPackage(item, 4).label }}
      template(v-slot:item.fee4Value="{ item }")
        span.auction-package-fee-value#fee-value-4 {{ getFeeValueDisplay(getFeeFromAuctionPackage(item, 4).value) }}

      template(v-slot:item.fee5Label="{ item }")
        span.auction-package-fee-label#fee-label-5 {{ getFeeFromAuctionPackage(item, 5).label }}
      template(v-slot:item.fee5Value="{ item }")
        span.auction-package-fee-value#fee-value-5 {{ getFeeValueDisplay(getFeeFromAuctionPackage(item, 5).value) }}

      template(v-slot:item.lastUpdatedOn="{ item }")
        div.auction-package-last-updated-date {{ formatDate(item.lastUpdatedOn).date }}
        div.auction-package-last-updated-time.caption.grey--text {{ formatDate(item.lastUpdatedOn).time }}

    v-divider
  v-btn.hidden-md-and-up(medium fab color='primary' router-link :to='"/auction-packages/create"' fixed bottom right v-show='!selected.length')
    v-icon(dark large) mdi-plus
</template>

<script>
import _ from 'lodash'
import csv from '../services/csv.service'
import defaultExchangeValues from '@/services/defaultExchangeValues'
import auctionPackageApi from '@/services/auctionPackage.api'
import auctionPackageService from '@/services/auctionPackage.service'
import componentConfigService from '@/services/componentConfig'
import actionConfirmationDialog from '@/components/actionConfirmationDialog.vue'
import confirmationDialog from '@/components/confirmationDialog.vue'
import sortableItemList from '@/components/sortableItemList.vue'
import filtersView from '@/components/filtersView.vue'
import filterSet from '@/components/filterSet.vue'

import moment from 'moment'
import mtz from 'moment-timezone'

export default {
  components: {
    actionConfirmationDialog,
    confirmationDialog,
    sortableItemList,
    filtersView,
    filterSet
  },
  created: function () {
    if (!this.$store.getters['general/isAuctionPackageVisible']) {
      this.$router.push({ name: '404' })
    }
    this.getData()
    this.canSeeNewUi = this.$flags.canSeeUiRebrand.isEnabled()
  },
  computed: {
    organizationId () {
      return this.$store.getters['user/getOrganization'].id
    },
    tableLoading () {
      return !this.organizationId || this.fetching
    },
    noDataText () {
      if (!this.data.length && this.search.length) {
        return 'No auction package matches your search'
      } else {
        return 'No auction package found'
      }
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    canSeeOrganizations () {
      return this.$store.getters['user/isGlobalAdmin']
    },
    defaultSortableColumns () {
      const columns = []

      if (this.canSeeOrganizations) columns.push({ text: 'Organization', value: 'organization', enabled: true })

      return columns.concat([
        { text: 'Created By', value: 'ownerEmail', enabled: false },
        { text: 'Market', value: 'exchange', enabled: true },
        { text: 'Auction Package Name', value: 'name', enabled: true },
        { text: 'External ID', value: 'externalId', enabled: false, sortable: false },
        { text: 'Deal ID', value: 'code', enabled: true },
        { text: 'Weekly Impressions', value: 'estimatedImpressions', enabled: true },
        { text: 'Venue Count', value: 'estimatedNumberOfVenues', enabled: true },
        { text: 'Screen Count', value: 'estimatedNumberOfFaces', enabled: true },
        { text: 'Publishers', value: 'targetedSuppliers', enabled: false, width: '350px' },
        { text: 'Creative Formats', value: 'targetedCreativeFormat', enabled: false, width: '200px' },
        { text: 'Creative Type', value: 'targetedCreativeType', enabled: false },
        { text: 'Start Date', value: 'startDate', enabled: true, width: '8%' },
        { text: 'End Date', value: 'endDate', enabled: true, width: '8%' },
        { text: 'Floor Price', value: 'floorPrice', enabled: true },
        { text: 'Min Bid Range', value: 'minBidRange', enabled: false },
        { text: 'Max Bid Range', value: 'maxBidRange', enabled: false },
        { text: 'Media Fee 1 Label', value: 'fee1Label', enabled: false, sortable: false },
        { text: 'Media Fee 1 Value', value: 'fee1Value', enabled: false, sortable: false },
        { text: 'Media Fee 2 Label', value: 'fee2Label', enabled: false, sortable: false },
        { text: 'Media Fee 2 Value', value: 'fee2Value', enabled: false, sortable: false },
        { text: 'Media Fee 3 Label', value: 'fee3Label', enabled: false, sortable: false },
        { text: 'Media Fee 3 Value', value: 'fee3Value', enabled: false, sortable: false },
        { text: 'Media Fee 4 Label', value: 'fee4Label', enabled: false, sortable: false },
        { text: 'Media Fee 4 Value', value: 'fee4Value', enabled: false, sortable: false },
        { text: 'Media Fee 5 Label', value: 'fee5Label', enabled: false, sortable: false },
        { text: 'Media Fee 5 Value', value: 'fee5Value', enabled: false, sortable: false },
        { text: 'Last Updated', value: 'lastUpdatedOn', enabled: true, width: '8%' }
      ])
    },
    sortableColumns () {
      return this.customizedSortableColumns ?? this.defaultSortableColumns
    },
    headers () {
      const enabledColumns = this.sortableColumns.filter(c => c.enabled)
      return [this.statusHeader, ...enabledColumns]
    },
    filterOptions () {
      return [
        { name: 'Organization', id: 'organization', operators: ['is any of', 'is none of', 'contains'] },
        { name: 'Created By', id: 'ownerEmail', operators: ['is any of', 'is none of', 'contains'] },
        { name: 'Market', id: 'exchange', operators: ['is any of', 'is none of'], options: defaultExchangeValues.getAllDefaultValues().map(x => x.countryCode) },
        { name: 'Has Start Date', id: 'startDate', operators: ['has any value'] },
        { name: 'Status', id: 'status', operators: ['is any of', 'is none of'] },
        { name: 'Auction Package', id: 'auctionPackage', operators: ['is any of', 'is none of', 'contains'] },
        { name: 'External ID', id: 'externalId', operators: ['contains'] },
        { name: 'Publishers', id: 'targetedSuppliers', operators: ['is any of', 'is none of'] },
        { name: 'Creative Formats', id: 'targetedCreativeFormat', operators: ['is any of', 'is none of'] },
        { name: 'Creative Type', id: 'targetedCreativeType', operators: ['is any of', 'is none of'] },
        { name: 'Media Fees Label', id: 'feesLabel', operators: ['contains'] },
        { name: 'Media Fee 1 Label', id: 'fee1Label', operators: ['contains'] },
        { name: 'Media Fee 2 Label', id: 'fee2Label', operators: ['contains'] },
        { name: 'Media Fee 3 Label', id: 'fee3Label', operators: ['contains'] },
        { name: 'Media Fee 4 Label', id: 'fee4Label', operators: ['contains'] },
        { name: 'Media Fee 5 Label', id: 'fee5Label', operators: ['contains'] }
      ]
    }
  },
  data () {
    return {
      pagination: {
        sortBy: ['lastUpdatedOn'],
        sortDesc: [true],
        itemsPerPage: 50,
        page: 1
      },
      statusHeader: { text: 'Status', value: 'status', width: '2%', filterOperators: ['is any of', 'is none of'] },
      data: [],
      selected: [],
      search: this.$route?.query?.search || '',
      lastSearch: '',
      currentTab: 'All',
      currentTabIdx: 0,
      totalItems: 0,
      fetching: true,
      selectAll: false,
      tabs: ['All', 'Archived'],
      isStatusLoading: null,
      newColumns: null,
      customizedSortableColumns: null,
      columnsValueMapping: {
        organization: 'organization/name',
        exchange: 'exchange/key',
        minBidRange: 'floorPrice',
        maxBidRange: 'ceilingPrice',
        auctionPackage: 'code'
      },
      addedFilters: JSON.parse(localStorage.auctionPackageFilter || '[]') || []
    }
  },
  watch: {
    search: _.debounce(function () {
      this.getData()
    }, 450),
    selected (newVal, oldVal) {
      if (newVal.length !== this.data.length) {
        this.selectAll = false
      }
    },
    pagination (newVal, oldVal) {
      if (this.organizationId) {
        this.getData()
      }
    },
    currentTab (newVal, oldVal) {
      if (oldVal !== '') {
        // reset Paging
        this.$set(this.pagination, 'page', 1)

        this.getData()
      }
    },
    addedFilters () {
      this.getData()
    }
  },
  mounted () {
    this.loadColumns()
  },
  methods: {
    changeTab (newVal) {
      this.currentTab = this.tabs[newVal]
      this.currentTabIdx = newVal
    },
    archiveAuctionPackages () {
      var action = this.currentTab !== 'Archived' ? 'Archived' : 'Inactive'
      const isPlural = this.selected.length > 1
      this.selected.forEach((ap) =>
        this.updateStatus(ap.name, ap.id, action, ap.exchange.id, isPlural, true)
      )
      this.selected = []
    },
    updateStatus (name, id, status, exchangeId, isPlural = false, removeUpdatedItem = false) {
      this.isStatusLoading = id

      auctionPackageApi.editAuctionPackage(id, { status: status, exchange: { id: exchangeId } }).then(resp => {
        if (resp.status === 200) {
          this.$store.commit('snackbar/setSnackbar', {
            type: 'success',
            msg: isPlural ? 'Auction packages successfully updated' : name + ' successfully updated'
          })
          if (removeUpdatedItem) {
            const index = this.data.findIndex(x => x.id === resp.data.id)
            this.data.splice(index, 1)
            this.totalItems = this.totalItems - 1
          }
        }
      })
        .catch(error => {
          let msg = error.response.data.errors[0]
          if (msg.errorCode === 'unexpected') {
            msg = 'Unexpected: ' + msg.id
          } else msg = msg.message

          this.$store.commit('snackbar/setSnackbar', {
            type: 'error',
            msg: `${msg}`
          })
        })
        .finally(() => {
          this.isStatusLoading = null
        })
    },
    marketLabel (item) {
      if (!item || !item.exchange || !item.exchange.key) {
        return ''
      }

      return defaultExchangeValues.getAllDefaultValues().find(x => x.market === item.exchange.key).countryCode
    },
    getCreateAuctionPackagePath () {
      return this.$router.resolve({ name: 'Create Auction Package' }).href
    },
    getSingleViewPath (id) {
      return this.$router.resolve({ name: 'Auction Package Page', params: { itemId: id } }).href
    },
    buildFiltersList () {
      var filters = []

      if (this.search) {
        filters.push({ name: 'search', value: this.search })
      }
      if (this.currentTab === 'Archived') {
        filters.push({ name: 'mainStatus', keyName: 'status', value: 'Archived' })
      } else {
        filters.push({ name: 'mainStatus', keyName: 'status', value: 'All' })
      }

      const addedFiltersList = this.addedFilters.map(filter => {
        const column = filter.column.id in this.columnsValueMapping ? this.columnsValueMapping[filter.column.id] : filter.column.id
        if (filter.operator === 'contains') {
          return { name: 'multiFieldsSearch', value: filter.value, fields: [column] }
        } else {
          return { name: 'multiValuesSearch', values: filter.value, field: column, operator: filter.operator }
        }
      })
      return filters.concat(addedFiltersList)
    },
    getData () {
      this.fetching = true

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination

      const skip = (page - 1) * itemsPerPage
      const take = itemsPerPage
      const sort = sortDesc[0] ? 'desc' : 'asc'
      const orderBy = sortBy[0] in this.columnsValueMapping ? this.columnsValueMapping[sortBy[0]] : sortBy[0]
      // Filters
      var filters = this.buildFiltersList()

      auctionPackageApi.getAuctionPackageCount(filters)
        .then(count => {
          this.totalItems = count
        })

      auctionPackageApi.getAuctionPackages(skip, take, orderBy, sort, filters, true)
        .then(data => {
          this.fetching = false
          this.data = data

          if (this.selectAll) {
            this.selected = data
          }
        })
    },
    duplicateAuctionPackage ({ organizationId }) {
      const threshold = 50
      const picked = this.selected.length > threshold ? this.selected.slice(0, threshold) : this.selected

      const isPlural = this.selected.length > 1
      picked.forEach(auctionPackage => {
        this.$store.dispatch('auctionPackage/duplicate', { auctionPackageId: auctionPackage.id, organizationId })
          .then(updatedAuctionPackage => {
            if (auctionPackage.status !== 'Archived') {
              const index = this.data.findIndex(x => x.id === auctionPackage.id)
              this.data.splice(index, 0, updatedAuctionPackage)
              this.totalItems = this.totalItems + 1
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'success',
              msg: isPlural ? 'Auction packages successfully duplicated' : auctionPackage.name + ' successfully duplicated'
            })
          })
          .catch(error => {
            let msg = error.data.errors[0].errorCode
            if (msg === 'unexpected') {
              msg = 'Unexpected: ' + error.data.errors[0].id
            }

            this.$store.commit('snackbar/setSnackbar', {
              type: 'error',
              msg: `${msg}`
            })
          })
      })
      this.selected = []
    },
    exportCsv () {
      const params = this.selectAll ? { filters: this.buildFiltersList() } : { ids: this.selected.map(x => x.id) }

      auctionPackageApi.getAuctionPackageListForCSV(params).then(res => {
        csv.csvExport(res.map(x => auctionPackageService.csvFormatter(x)), 'AuctionPackages_' + moment().format('DD-MM-YYYY'))
      })
    },
    formatDate (date) {
      return {
        date: date ? mtz.tz(date, null).format('MMM Do, YYYY') : '-',
        time: date ? mtz.tz(date, null).format('h:mm A') : ''
      }
    },
    onColumnsChange (items) {
      this.newColumns = items
    },
    saveColumns () {
      if (this.newColumns) {
        this.customizedSortableColumns = this.newColumns
        const simplifiedColumns = this.newColumns.map(c => { return { value: c.value, enabled: c.enabled } })
        localStorage.setItem('auctionPackageColumns', JSON.stringify(simplifiedColumns))
      }
    },
    loadColumns () {
      const localStorageColumnsJson = localStorage.getItem('auctionPackageColumns')
      if (localStorageColumnsJson) {
        try {
          const localStorageColumns = JSON.parse(localStorageColumnsJson)
          const defaultColumnsCopy = JSON.parse(JSON.stringify(this.defaultSortableColumns))

          const loadedColumns = []
          localStorageColumns.forEach((localStorageColumn) => {
            const defaultColumnIndex = defaultColumnsCopy.findIndex((defaultColumn) => defaultColumn.value === localStorageColumn.value)
            if (defaultColumnIndex >= 0) {
              loadedColumns.push({ ...defaultColumnsCopy[defaultColumnIndex], ...localStorageColumn })
              defaultColumnsCopy.splice(defaultColumnIndex, 1)
            }
          })

          this.customizedSortableColumns = [...loadedColumns, ...defaultColumnsCopy] // If there's a new column that was not saved locally, it will be in defaultColumnsCopy
        } catch (e) {
          localStorage.removeItem('auctionPackageColumns')
        }
      }
    },
    getFeeFromAuctionPackage (auctionPackage, feeLayoutOrder) {
      return auctionPackage?.fees?.find(fee => fee.layoutOrder === feeLayoutOrder) ?? {}
    },
    getFeeValueDisplay (feeValue) {
      return feeValue || feeValue === 0 ? `${Math.round(feeValue * 10000) / 100}%` : ''
    },
    addFilter (filter) {
      this.addedFilters.push({ ...filter })
      this.saveFilter()
    },
    deleteFilter (index) {
      this.addedFilters.splice(index, 1)
      this.saveFilter()
    },
    clearFilter () {
      this.addedFilters.splice(0, this.addedFilters.length)
      this.saveFilter()
    },
    updateFilter (obj) {
      const { filter, index } = obj
      const copy = [...this.addedFilters]
      copy[index] = { ...filter }
      this.addedFilters = copy
      this.saveFilter()
    },
    saveFilter () {
      localStorage.auctionPackageFilter = JSON.stringify([...this.addedFilters
        .filter(({ column: { id } }) => id !== 'ownerEmail')
        .map((filter) => filter.field === 'code' ? {
          ...filter,
          value: filter.value.substring(
            filter.value.slice(filter.value.lastIndexOf('(') + 1, filter.value.length - 1))
        }
          : filter)])
    },
    filterSearch (resourceType, searchValue) {
      const column = resourceType in this.columnsValueMapping ? this.columnsValueMapping[resourceType] : resourceType
      return auctionPackageService.searchUniqueValuesForColumn(column, searchValue)
    },
    filterToLabel (filter) {
      let label = filter.column.name

      if (filter.operator !== 'has any value') label += ' ' + filter.operator
      if (filter.value) {
        label += ': '
        label += typeof filter.value === 'object' ? filter.value.join(', ') : filter.value
      }

      return label
    }
  }
}
</script>
<style lang="stylus" scoped>
  >>> .v-data-table-header th {
    white-space: nowrap;
  }

  .auction-package-fee-label {
    white-space: nowrap;
  }
</style>
