<template lang="pug">
div
  creatives-formats-table(
    :creatives-formats='requiredFormats'
    :assigned-creatives='assignedCreatives || []'
    :show-assigned='showAssigned')

  v-row.ma-0
    v-col(xs=12)
      .text-caption
        strong
          | Format:
        |  .jpg, .png, .mp4, .mov, .webm
      .text-caption
        strong
          | Duration:
        |  Up to 30 seconds
      .text-caption
        strong
          | File Size:
        |  Max 250 MB

      p.text-caption.mt-2 Creatives can be slightly cropped to accommodate suppliers’ screen specifications. We suggest to avoid positioning logos and text close to the edges. For best results, leave a 5% margin on each side. Please review our
        a.info--text(href='https://broadsign.com/programmatic-privacy-policy/' target='_blank' style='text-decoration:none;')  platform policy
        |  before submitting.
</template>
<script>
import creativesFormatsTable from '@/components/creativesFormatsTable'

export default {
  components: {
    creativesFormatsTable
  },
  props: ['requiredFormats', 'showAssigned', 'assignedCreatives']
}
</script>
