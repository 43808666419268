<template lang="pug">
  v-expansion-panel#creatives-panel
    v-expansion-panel-header
      template(v-slot:default='{ open }')
        v-row.my-0(no-gutters align="center")
          v-col.text-h6(cols='4') Creatives
          v-col.text--secondary(cols='7')
            .creatives-panel-header
              v-scroll-y-reverse-transition.d-block(leave-absolute)
                div(v-if='open' key='0') &nbsp;
                div(v-else key='1') Missing {{ numberOfMissingCreatives }} creative formats
    v-expansion-panel-content(ref='creatives-panel-content')
      div
        div.text-body-2
          a.info--text(:href='componentConfig.links.uploadCreatives' target='_blank')
            span Learn more about creative upload and specifications
            v-icon(small color='info') mdi-launch

        creatives-formats-table(
          :creatives-formats='requiredFormats'
          :assigned-creatives='assignedCreatives'
        )

        creativesUploaderBtn.my-4(
          @creativeUploaded='creativeUploaded'
          :upload-data='uploadData')

        assignedCreativesTable(
          @removeCreative='removeCreativeFromCampaign'
          parent='panelCreatives')

        div(:class="{'mx-n6': $vuetify.breakpoint.xsOnly}")
          availableCreativesTable(
            :advertiser='advertiser'
            :exchange='exchange'
            parent='panelCreatives'
            @addCreative='addCreativeToCampaign')

        creativeInfos
</template>

<script>
import creativesFormatsTable from '@/components/creativesFormatsTable'
import creativesUploaderBtn from '@/components/creativesUploaderBtn.vue'
import assignedCreativesTable from '@/components/assignedCreativesTable.vue'
import availableCreativesTable from '@/components/availableCreativesTable.vue'
import creativeInfos from '@/components/creativeFooterInfos.vue'

import helpersService from '@/services/helpers.service'
import componentConfigService from '@/services/componentConfig'

export default {
  components: {
    creativesFormatsTable,
    creativesUploaderBtn,
    assignedCreativesTable,
    availableCreativesTable,
    creativeInfos
  },
  data () {
    return {
      saving: false
    }
  },
  props: ['line'],
  mounted () {
  },
  computed: {
    requiredFormats () {
      return this.$store.getters['audience/forecast'].formats
    },
    assignedCreatives () {
      return this.$store.getters['createCampaign/getCreatives']
    },
    uniquesRequiredFormats () {
      const uniquesRequiredFormatsKeys = this.requiredFormats.map(rf => rf.key)
      return [...new Set(uniquesRequiredFormatsKeys)]
    },
    numberOfMissingCreatives () {
      const missingList = this.uniquesRequiredFormats
        .map(key => this.formatCount(key) > 0 ? 0 : 1)
      return missingList.length ? missingList.reduce((sum, currentVal) => sum + currentVal) : ''
    },
    advertiser () {
      return this.$store.getters['createCampaign/getAdvertiser']
    },
    exchange () {
      return this.$store.getters['createCampaign/getExchange']
    },
    uploadData () {
      var data = []

      if (this.advertiser && this.advertiser.id) {
        data.push({ name: 'accountId', value: this.advertiser.id })
      }

      var industry = this.$store.getters['createCampaign/getIndustry']
      if (industry) { data.push({ name: 'industry', value: industry.name }) }
      return data
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    formatLabel (format) {
      return format.minWidth.toString() + ' x ' + format.minHeight.toString()
    },
    formatCount (formatKey) {
      return this.$store.getters['createCampaign/getCreativesPerFormat'](formatKey).length
    },
    creativeUploaded (creative) {
      this.$store.commit('creatives/removeUploadingCreative', creative)
      this.$store.commit('createCampaign/addCreative', creative)
      this.$emit('updateForecast')
    },
    addCreativeToCampaign (creative) {
      this.$store.commit('createCampaign/addCreative', creative)
      this.$emit('updateForecast')
    },
    removeCreativeFromCampaign (creative) {
      this.$store.commit('createCampaign/removeCreative', creative.id)
      this.$emit('updateForecast')
    },
    formatNumber (nbr) {
      return helpersService.shortenNumber(nbr)
    }
  }
}
</script>
