<template lang='pug'>
div
  vue-headful(:title="componentConfig.branding.title(pageHeader)")

  //- v-toolbar.pt-3.elevation-0(color='transparent')
    v-container.py-2(grid-list-lg text-left fluid)
      v-layout(row wrap justify-start align-center  style='min-height: 68px;')
        v-flex(xs12)
          .d-flex.align-center
            v-btn.text-h6.ml-0.mr-2(fab small color='white' router-link :to="{name: 'Manage Organizations'}")
              v-icon(medium) mdi-arrow-left
            .text-h6.d-inline-block.text-truncate(class='secondary--text') {{ pageHeader }}

  v-navigation-drawer#settings-menu(
    v-if='!$flags.canSeeUiRebrand.isEnabled()'
    v-show='$vuetify.breakpoint.lgAndUp'
    clipped fixed permanent style='top:48px;'
    )
    v-list
      v-subheader
        v-icon.mr-1(small) mdi-cog
        | ORGANIZATION SETTINGS
      v-divider
      v-list-item-group(:value='visibleSection' color='primary' @change='scrollToSection')
        v-list-item.settings-menu-links(value='general')
          v-list-item-content
            v-list-item-title General Information
        v-list-item.settings-menu-links(value='users')
          v-list-item-content
            v-list-item-title User Management
        v-list-item.settings-menu-links.fees(value='fees')
          v-list-item-content
            v-list-item-title Fees
        v-list-item.settings-menu-links.inventory(value='inventory' v-if='inventoryAccessVisible')
          v-list-item-content
            v-list-item-title Inventory Access
        v-list-item.settings-menu-links(value='theme' v-if='can.update.organization')
          v-list-item-content
            v-list-item-title Theme
        v-list-item.settings-menu-links(value='billing')
          v-list-item-content
            v-list-item-title Billing Options

  v-form(ref='orgForm' v-model='formValidated')
    v-container(grid-list-lg text-xs-left)
      v-layout(row wrap)
        v-flex(xs12 md9 lg8 xl6 offset-md2 offset-lg3)
          v-card.my-6(id='general' :loading='pageLoading' v-intersect="{ handler: switchSection, options: { threshold: [0.8] }}")
            v-card-title.text-h5 General Information
            v-card-text.secondary--text
              v-layout(row wrap)
                v-flex(sm12 md12)
                  v-layout(row wrap)
                    v-flex(sm12 md12)
                      v-text-field(label='Organization or Business Name' v-model='orgInfo.name' hide-details :rules='[rules.required]')
                  v-layout(row wrap)
                    v-flex(sm6)
                      v-select(
                        label='Currency'
                        :disabled='isEditPage'
                        v-model='orgInfo.currency'
                        :items='currencies'
                        item-text='label'
                        item-value='code'
                        return-object
                        :rules='[rules.required]'
                        persistent-hint
                        hint='Cannot be changed.')
                    v-flex(sm6)
                      v-select(
                        label='Time Zone'
                        :disabled='isEditPage'
                        v-model='orgInfo.timeZone'
                        :items='timeZones'
                        item-text='label'
                        item-value='value'
                        :rules='[rules.required]'
                        persistent-hint
                        hint='Cannot be changed.')

                  v-layout(row wrap)
                    v-flex(sm12 md12)
                      //-.text-body-2.my-2
                        | This organization operates under the Workspace
                        strong  Dentsu

                      //- v-alert.my-3(outlined dense type='info' color='primary')
                        | Signed Managed Service Agreement with and additional bidding fee of
                        strong  10%

                //-v-flex(sm12 md5)
                  v-card.pa-3.ml-6(outlined)
                    .text-body-1
                      strong Organisation's Logo
                    .text-caption.grey--text  The avatar appears on campaign proposals sent to clients. Specifications: 192px X 192px
                    .align-center.justify-center.text-center.ma-3
                      v-avatar(size='120')
                        v-img(src='https://picsum.photos/id/11/500/500')
                    div
                      v-btn.d-block.mx-auto.mb-1(color='primary' rounded small) Upload Image
                      v-btn.d-block.mx-auto(color='primary' text small) Remove

              v-expansion-panels.mt-3(multiple)
                v-expansion-panel
                  v-expansion-panel-header
                    template(v-slot:default='{ open }')
                      v-row.my-0(no-gutters)
                        v-col(cols='12' md='2')
                          .text-body-1
                            strong Address
                        v-col.text-left.d-none.d-sm-flex.justify-start.align-center(md='10')
                          v-fade-transition.d-inline(leave-absolute)
                            span(v-if='open' key='0')
                            span(v-else key='1')
                              div.d-flex.grey--text {{ addressInline }}

                  v-expansion-panel-content
                    v-layout(row wrap)
                      v-flex(sm12 md9)
                        v-text-field(label='Address Line 1' v-model='orgAddress.addressLine1')
                      v-flex(sm12 md3)
                        v-text-field(label='Suite / Office Number' v-model='orgAddress.addressLine2')

                    v-layout(row wrap)
                      v-flex(sm12 md6)
                        v-text-field(label='City' v-model='orgAddress.city')
                      v-flex(sm12 md6)
                        v-text-field(:label='marketVals.postcodeLabel' v-model='orgAddress.postalCode')

                    v-layout(row wrap)
                      v-flex(sm12 md6)
                        v-autocomplete(label='Country' v-model='orgAddress.country' return-object :items='countriesAndStates' item-text='name' autocomplete='nope')
                      v-flex(sm12 md6)
                        v-autocomplete(:label='marketVals.stateLabel' v-model='orgAddress.state' return-object :items='countryStates' item-text='name' autocomplete='nope')

                v-expansion-panel
                  v-expansion-panel-header
                    template(v-slot:default='{ open }')
                      v-row.my-0(no-gutters)
                        v-col(cols='12' md='2')
                          .text-body-1
                            strong Contact
                        v-col.text-left.d-none.d-sm-flex.justify-start.align-center(md='10')
                          v-fade-transition
                            div(v-show='!open' key='0' style='width:100%;')
                              v-row.my-0.grey--text(no-gutter)
                                v-col.py-0(md='4')
                                  div {{ primaryContact.firstName }} {{ primaryContact.lastName }}
                                v-col.py-0(md='5')
                                  div {{ primaryContact.email }}
                                v-col.py-0(md='3')
                                  div {{ primaryContact.phone }}

                  v-expansion-panel-content
                    v-layout.mb-1(row wrap)
                      v-flex(sm12 md6)
                        v-text-field(label='First Name' v-model='primaryContact.firstName')
                      v-flex(sm12 md6)
                        v-text-field(label='Last Name' v-model='primaryContact.lastName')

                    v-layout(row wrap)
                      v-flex(sm12 md6)
                        v-text-field(label='Email' v-model='email' prepend-inner-icon='mdi-email' :rules='rules.validEmailIfProvided')
                      v-flex(sm12 md6)
                        v-text-field(label='Phone Number' v-model='primaryContact.phone' prepend-inner-icon='mdi-phone')

                v-expansion-panel#reports-api-panel(v-if='this.$flags.canSeeReportApiSection.isEnabled()')
                  v-expansion-panel-header
                    template(v-slot:default='{ open }')
                      v-row.my-0(no-gutters)
                        v-col(cols='12' md='2')
                          .text-body-1
                            strong API Access
                        v-col.text-left.d-none.d-sm-flex.justify-start.align-center(md='10')
                          v-fade-transition
                            .api-access-status(v-show='!open && !apiKeyLoading' key='0' style='width:100%;')
                              v-row.my-0.grey--text(no-gutter)
                                v-col.py-0(md='4')
                                  div(v-if='apiKey') Enabled
                                  div(v-else) Disabled

                  v-expansion-panel-content
                    .api-access-instruction(v-if='!can.update.apiKey && !apiKey') Please contact your account manager to activate the API access.
                    v-switch.api-key-switch(
                      :input-value='!!apiKey'
                      :loading='apiKeyLoading'
                      :label="`API access ${apiKey ? 'enabled' : 'disabled' }`"
                      :disabled='!can.update.apiKey || apiKeyLoading'
                      @change='toggleApiAccess'
                      )
                    div.grey--text
                      | Resources:
                      a.info--text(href='https://docs.broadsign.com/broadsign-ads' target='_blank')  API Documentation
                      //- |  and
                      //- a.info--text  API Policies

                v-expansion-panel#setup-panel
                  v-expansion-panel-header
                    template(v-slot:default='{ open }')
                      v-row.my-0(no-gutters)
                        v-col(cols='12' md='2')
                          .text-body-1
                            strong Setup
                        v-col.text-left.d-none.d-sm-flex.justify-start.align-center(md='10')
                          v-fade-transition
                            div(v-show='!open' key='0' style='width:100%;')
                              v-row.my-0.grey--text(no-gutter)
                                v-col.py-0(md='4')
                                  div(v-if='isBroadsignAdsOrganization') {{ paymentMethod.label }}
                                  div(v-if='!isBroadsignAdsOrganization && orgInfo.dspExternalId') DSP Partner : {{ orgInfo.dspExternalId }}

                  v-expansion-panel-content
                    v-layout.mb-1(row wrap)
                      v-flex(sm12 md12)
                        v-radio-group(v-model='orgInfo.type')
                          p.font-weight-bold Campaign execution via
                          v-radio.default-radio-btn(ref="default" :disabled='campaignExecutionLocked || !can.update.campaignExecution' label='Broadsign Ads' value='Default' color='primary')
                          v-radio.partner-dsp-radio-btn(ref="partnerDsp" :disabled='campaignExecutionLocked || !can.update.campaignExecution' label='DSP Partner' value='PartnerDsp' color='primary')
                        div(v-if='can.read.buyerSeat')
                          p.font-weight-bold.font-size-16 Buyer Seat
                          | A seat is a unique identifier allowing a publisher to specifically target your organization when creating a&nbsp;
                          span(style='white-space: nowrap;') Deal ID.
                          v-text-field.reach-dsp-id(id='reachDspId' counter='64' :rules="[rules.required, rules.counter]" :disabled='campaignExecutionLocked' label='Reach DSP ID' v-model='orgInfo.dspExternalId' v-if='!isBroadsignAdsOrganization')
                          v-row.my-0
                            v-col(md='6')
                              v-row.my-0
                                v-col(cols='2')
                                  v-switch.buyer-seat-switch(v-model='hasBuyerSeat' color='primary' @change='toggleSeat' :disabled='!can.update.buyerSeat || seatLocked')
                                v-col(align-self='center')
                                  v-scroll-y-transition(leave-absolute)
                                    div(v-if='!hasBuyerSeat')
                                      .body-1 No buyer seat set
                                      a.info--text.caption(v-if='!can.update.buyerSeat' href='mailto:info_ads@broadsign.com')  Contact support to enable your seat
                                      .caption(v-if='!can.update.buyerSeat') Certain conditions apply.
                                  v-scroll-y-transition(leave-absolute hide-on-leave)
                                    div(v-if='hasBuyerSeat')
                                      v-text-field.reach-buyer-seat(id='buyerSeat' label='Buyer Seat ID' hide-details :readonly='!can.update.buyerSeat' :loading='seatLoading' v-model='seat' :disabled='seatLocked')
                        v-select.my-3(
                          v-if='isBroadsignAdsOrganization'
                          ref='paymentMethodSelect'
                          label='Payment Options'
                          v-model='paymentMethod'
                          :disabled='!can.update.paymentType'
                          :items='paymentMethodsOptions'
                          item-value='key'
                          item-text='label'
                          return-object
                          hide-details)

            v-card-actions
              .text-caption.my-3.ml-3(v-if='!pageLoading')
                router-link.info--text(to='/terms-conditions' target='_blank') Terms and Conditions
                span.error--text(v-if='!termsAccepted')
                  strong  NOT accepted
                span.grey--text(v-if='termsAccepted')
                  span  accepted by
                  strong  {{ termsAcceptedByUser }}
                  //- span  on December 21st, 2019
              v-spacer

              v-btn.save-btn(v-if='can.update.organization' text color='primary' :disabled='!formChangedAndValidated' :loading='formProcessing' @click='saveGeneral') Save Changes

          organizationUsers(
            id='users'
            v-intersect="{ handler: switchSection, options: { threshold: [0.8] }}"
            :organization-id='organizationId'
            :can-update='can.update.user'
            :full-table='$vuetify.breakpoint.xsOnly')

          organizationSettingsFees(
            id='fees'
            :organization-id='organizationId'
            :pageLoading='pageLoading'
            :canEditFees='can.update.fees')

          organizationSettingsInventory(
            id='inventory'
            v-if='inventoryAccessVisible'
            :organization-id='organizationId'
          )

          organizationTheme(
            id='theme'
            v-intersect="{ handler: switchSection, options: { threshold: [0.8] }}"
            v-if='can.update.organization'
            :organization-id='organizationId'
          )
          organizationSettingsBilling(
            id='billing'
            v-intersect="{ handler: switchSection, options: { threshold: [0.5] }}"
            v-if='!pageLoading'
            :page-loading='pageLoading'
            :parent-form-processing='formProcessing'
            :organization='rawOrg'
            :countries-and-states='countriesAndStates'
            @billingInfosUpdated='saveBilling'
            :can='orgPerms')

          //-.text-md-right
            v-spacer
            v-btn.my-3.mx-0(rounded large color='primary' :block='$vuetify.breakpoint.xsOnly' :loading='formProcessing' :disabled='!formValidated' @click='save()') {{ isEditPage? 'save changes' : 'add organization'}}
</template>

<script>
import billingService from '@/services/billing.service'
import geoService from '@/services/geo.service'
import helpers from '@/services/helpers.service'
import userApi from '@/services/user.api'
import userService from '@/services/user'
import tracking from '@/services/tracking'
import componentConfigService from '@/services/componentConfig'

import organizationUsers from '@/components/organizationUsers'
import organizationTheme from '@/components/organizationTheme'
import organizationSettingsFees from '@/components/organizationSettingsFees'
import organizationSettingsInventory from '@/components/organizationSettingsInventory'
import organizationSettingsBilling from '@/components/organizationSettingsBilling'

export default {
  components: {
    organizationUsers,
    organizationTheme,
    organizationSettingsFees,
    organizationSettingsInventory,
    organizationSettingsBilling
  },
  data () {
    return {
      pageLoading: true,
      visibleSection: '',
      initialScrollDone: false,
      rawOrg: null,

      timeZones: [],
      currencies: [],
      countriesAndStates: [],
      apiKey: null,
      apiKeyLoading: true,

      // General Information
      orgInfo: {
        name: '',
        currency: null,
        timeZone: '',
        dspExternalId: '',
        type: ''
      },

      orgAddress: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        postalCode: '',
        country: null,
        state: null
      },

      primaryContact: {
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
      },
      email: '',

      hasBuyerSeat: false,
      seatToggled: false,
      seatLoading: false,
      seat: '',
      campaignExecutionLocked: false,
      seatLocked: false,
      // General Information > Setup
      workspaceOptions: ['Dentsu', 'OMGP', 'Publicis', 'Group M', 'IPG', 'Cossette'],
      workspace: 'Dentsu',
      accountManagementOptions: ['Self-Serve', 'Managed Service'],
      accountManagement: 'Self-Serve',

      paymentMethodsOptions: [
        {
          id: 1,
          key: 'stripe',
          paymentType: 'Automatic',
          label: 'Automatic - Credit Card'
        },
        {
          id: 2,
          key: 'manual',
          paymentType: 'Manual',
          label: 'Manual Invoicing'
        },
        {
          id: 4,
          key: 'saas-for-publishers',
          paymentType: 'Manual',
          label: 'SaaS for Publishers'
        }
      ],
      paymentMethods: [],
      paymentMethod: {},

      // Form
      formValidated: false,
      formProcessing: false,
      rules: {
        required: value => !!value || 'Required',
        validEmail: v => /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'E-mail must be valid',
        validEmailIfProvided: [],
        counter: value => value.length <= 64 || 'Too long.'
      }
    }
  },
  created () {
    geoService.getCountriesAndStates()
      .then(countriesAndStates => {
        this.countriesAndStates = countriesAndStates
      })

    userApi.getCurrencies()
      .then(currencies => {
        this.currencies = currencies
          .map(c => {
            // build prettier label
            c.label = c.name + ' (' + c.code + ')'
            return c
          })
      })

    if (this.isEditPage) {
      this.getOrganization()
    } else {
      this.pageLoading = false
    }
  },
  computed: {
    marketVals () {
      return this.$store.getters['general/marketDefaultValues']
    },
    organizationId () {
      return userService.extractUserOrgId(this.$route.params, this.$store.getters['user/getOrganization'])
    },
    isEditPage () {
      return this.organizationId !== 'add'
    },
    isBroadsignAdsOrganization () {
      return this.orgInfo.type === 'Default'
    },
    pageHeader () {
      if (this.isEditPage) return this.orgInfo.name.length ? 'Edit Organization: ' + this.orgInfo.name : 'Edit Organization'
      return 'Add Organization'
    },
    orgPerms () {
      return this.$store.getters['user/permissions']('organization')
    },
    can () {
      const userPerms = this.$store.getters['user/permissions']('user')
      return {
        read: {
          buyerSeat: this.orgPerms.read.buyerSeat
        },
        update: {
          user: userPerms.update.default,
          organization: this.orgPerms.update.default,
          buyerSeat: this.orgPerms.update.buyerSeat,
          paymentType: this.orgPerms.update.paymentType,
          campaignExecution: this.orgPerms.update.campaignExecution,
          fees: this.orgPerms.update.fees,
          apiKey: this.orgPerms.update.apiKey
        }
      }
    },
    countryStates () {
      return this.orgAddress.country && this.countriesAndStates.length
        ? this.countriesAndStates.find(c => c.id === this.orgAddress.country.id).states
        : []
    },
    addressInline () {
      var address = []

      if (this.orgAddress.addressLine1) { address.push(this.orgAddress.addressLine1) }
      if (this.orgAddress.addressLine2) { address.push(this.orgAddress.addressLine2) }
      if (this.orgAddress.postalCode) { address.push(this.orgAddress.postalCode) }
      if (this.orgAddress.city) { address.push(this.orgAddress.city) }
      if (this.orgAddress.state) { address.push(this.orgAddress.state.name) }

      return address.join(', ')
    },
    termsAccepted () {
      return this.rawOrg.isForAdServer || (this.rawOrg.termsVersion && this.rawOrg.termsVersion === this.$store.getters['user/getAppTermsVersion'])
    },
    termsAcceptedByUser () {
      var label = 'Unknown User #' + this.rawOrg.termsAcceptedBy
      var termsAcceptedByUser = this.rawOrg.users.find(u => u.id === this.rawOrg.termsAcceptedBy)
      if (termsAcceptedByUser && (termsAcceptedByUser.firstName || termsAcceptedByUser.lastName)) {
        label = termsAcceptedByUser.firstName + ' ' + termsAcceptedByUser.lastName
      }
      if (this.rawOrg.isForAdServer) {
        return 'Polaris Sync User #' + this.rawOrg.termsAcceptedBy
      }
      return label
    },

    nameChanged () {
      return this.rawOrg && this.rawOrg.name !== this.orgInfo.name
    },

    addressChanged () {
      if (!this.rawOrg) return false

      const stateChanged = this.orgAddress.state && (!this.rawOrg.address || !this.rawOrg.address.state || this.rawOrg.address.state.id !== this.orgAddress.state.id)
      const countryChanged = this.orgAddress.country && (!this.rawOrg.address || !this.rawOrg.address.country || this.rawOrg.address.country.id !== this.orgAddress.country.id)

      return this.orgAddress.addressLine1 !== this.rawOrg.address.addressLine1 ||
        this.orgAddress.addressLine2 !== this.rawOrg.address.addressLine2 ||
        this.orgAddress.city !== this.rawOrg.address.city ||
        this.orgAddress.postalCode !== this.rawOrg.address.postalCode ||
        stateChanged || countryChanged
    },

    contactChanged () {
      if (!this.rawOrg) return false

      const contact = this.rawOrg.contacts.length
        ? this.rawOrg.contacts[0]
        : { firstName: '', lastName: '', email: '', phone: '' }

      return this.primaryContact.firstName !== contact.firstName ||
        this.primaryContact.lastName !== contact.lastName ||
        this.email !== contact.email ||
        this.primaryContact.phone !== contact.phone
    },

    seatChanged () {
      return this.seatToggled || (this.rawOrg && this.rawOrg.seatName !== this.seat && this.seat)
    },

    setupChanged () {
      return this.paymentMethods.length && this.paymentMethods[0].paymentMethod.id !== this.paymentMethod.id
    },
    campaignExecutionChanged () {
      return this.rawOrg && this.orgInfo.dspExternalId && this.rawOrg.type !== this.orgInfo.type && this.orgInfo.dspExternalId.length > 0
    },
    formChangedAndValidated () {
      return this.formValidated && (this.nameChanged || this.addressChanged || this.contactChanged || this.seatChanged || this.setupChanged || this.campaignExecutionChanged)
    },

    isSaasOrg () {
      return this.paymentMethod && this.paymentMethod.key === 'saas-for-publishers'
    },
    inventoryAccessVisible () {
      return this.$store.getters['general/inventoryAccessVisible'] && this.can.update.organization && this.isSaasOrg
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    },
    organizationType () {
      return this.orgInfo.type
    }
  },
  watch: {
    // add email validation when value provided
    email (newVal, oldVal) {
      if (newVal.length) {
        this.rules.validEmailIfProvided.push(this.rules.validEmail)
      } else {
        this.rules.validEmailIfProvided = []
      }
    },
    organizationId (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getOrganization()
      }
    },
    organizationType (newVal, oldVal) {
      if (newVal !== oldVal && oldVal) {
        this.hasBuyerSeat = false
      }
    },
    hasBuyerSeat (newVal, oldVal) {
      if (oldVal) this.seat = null
    }
  },
  methods: {
    getOrganization () {
      userApi.getOrganization(this.organizationId)
        .then(res => {
          if (res.isForAdServer) {
            this.$logger.warn(`Polaris org ${res.id} settings page accessed by User ${this.$store.getters['user/getProfile'].id}`)
            this.$router.push({ name: 'Access Denied' })
          }

          if (res.timezone) {
            this.timeZones = helpers.formatTimezones([res.timezone])
          }

          // save a copy
          this.rawOrg = JSON.parse(JSON.stringify(res))

          billingService.getActivePaymentMethods(this.organizationId)
            .then(paymentMethods => {
              this.paymentMethods = paymentMethods

              this.prefillOrg()
              this.pageLoading = false
            })

          this.apiKeyLoading = true
          userApi.getOrganizationApiKey(this.organizationId).then(apiKey => {
            this.apiKey = apiKey
            this.apiKeyLoading = false
          })
        })
        .catch(() => {
          this.$router.push({ name: 'Access Denied' })
        })
    },
    prefillOrg () {
      // General Information
      this.orgInfo.name = this.rawOrg.name
      this.orgInfo.currency = this.rawOrg.currency
      this.orgInfo.timeZone = this.rawOrg.timezone

      if (this.rawOrg.type === 'PartnerDsp') {
        this.campaignExecutionLocked = true
        this.orgInfo.dspExternalId = this.rawOrg.dspExternalId
        if (this.rawOrg.seatName !== null) this.seatLocked = true
      }

      this.orgInfo.type = this.rawOrg.type

      if (this.rawOrg.address) {
        this.orgAddress = JSON.parse(JSON.stringify(this.rawOrg.address))
      }

      // Contact
      if (this.rawOrg.contacts.length) {
        this.primaryContact = JSON.parse(JSON.stringify(this.rawOrg.contacts[0]))
        this.email = this.rawOrg.contacts[0].email
      }

      // Seat
      this.hasBuyerSeat = !!this.rawOrg.seatName
      this.seat = this.rawOrg.seatName

      if (this.rawOrg.type === 'Default' && this.hasBuyerSeat) {
        this.campaignExecutionLocked = true
      }

      // Setup
      if (this.paymentMethods.length) {
        this.paymentMethod = this.paymentMethodsOptions.find(p => p.id === this.paymentMethods[0].paymentMethod.id)
      } else {
        const defaultPaymentmethod = this.paymentMethodsOptions.find(p => p.paymentType === this.rawOrg.paymentType)
        this.paymentMethod = defaultPaymentmethod
        this.paymentMethods = [Object.assign({}, defaultPaymentmethod, { paymentMethod: defaultPaymentmethod })]
      }
    },

    saveGeneral () {
      this.formProcessing = true
      let seatPromise = Promise.resolve()

      const data = {}

      if (this.nameChanged) {
        data.name = this.orgInfo.name
      }

      if (this.addressChanged) {
        data.address = this.orgAddress
      }

      if (this.seatChanged) {
        seatPromise = this.syncReachSeat().then(() => {
          data.seatName = this.seat
        })
      }

      if (this.campaignExecutionChanged) {
        data.type = this.orgInfo.type
        data.dspExternalId = this.orgInfo.dspExternalId
      }

      if (Object.keys(data).length || this.seatChanged) {
        seatPromise.then(() => {
          this.updateOrganization(data)
          this.formValidated = false
          this.seatToggled = false
        })
      }

      if (this.contactChanged) {
        this.processContact()
      }

      if (this.setupChanged) {
        this.updatePaymentMethod()
      }
    },

    updateOrganization (data) {
      data.id = this.organizationId

      return userApi.editOrganization(data)
        .then(res => {
          // update Store if editing own Organization
          if (this.$store.getters['user/getOrganization'].id === res.id) {
            this.$store.commit('user/setOrganization', res)
          }

          this.getOrganization()

          this.setSnackbar('Organization infos successfully updated.')
        })
        .catch(e => {
          this.setSnackbar(e)
        })
        .finally(() => {
          this.formProcessing = false
        })
    },

    processContact () {
      const contact = this.primaryContact
      contact.email = this.email

      const contactPromise = contact.id
        ? userApi.editOrganizationContact(this.organizationId, contact)
        : userApi.createOrganizationContact(this.organizationId, contact)

      contactPromise
        .then(res => {
          this.getOrganization()

          const verb = contact.id ? 'updated' : 'created'
          this.setSnackbar('Contact successfully ' + verb + '.')
        })
        .catch(e => {
          this.setSnackbar(e)
        })
        .finally(() => {
          this.formProcessing = false
        })
    },

    saveBilling (billingInfos) {
      this.formProcessing = true

      const fieldsUpdated = Object.keys(billingInfos)
      if (billingInfos.paymentTerm || fieldsUpdated.includes('billingAddress')) {
        billingInfos.id = this.rawOrg.id

        userApi.editOrganization(billingInfos)
          .then(res => {
            this.getOrganization()
            this.setSnackbar('Billing Infos successfully updated.')
          })
          .catch(e => {
            this.setSnackbar(e)
          })
          .finally(() => {
            this.formProcessing = false
          })
      }

      if (billingInfos.billingContacts) {
        this.saveBillingContacts(billingInfos.billingContacts)
      }
    },

    saveBillingContacts (billingContacts) {
      const originalList = this.rawOrg.billingContacts || []

      const orgId = this.rawOrg.id

      const originalListIds = originalList.map(x => x.id)
      const newListIds = billingContacts.map(x => x.id)

      const toAdd = []
      const toDelete = []

      billingContacts.forEach(item => {
        if (!originalListIds.includes(item.id)) {
          // ADD
          toAdd.push(item.email)
        } else if (originalList.find(x => x.id === item.id && x.email !== item.email)) {
          // PATCH (aka delete & add)
          toDelete.push(item.id)
          toAdd.push(item.email)
        }
      })

      // DELETE
      originalList.forEach(item => {
        if (!newListIds.includes(item.id)) {
          toDelete.push(item.id)
        }
      })

      const deletePromises = toDelete.length
        ? toDelete.map(id => {
          return userApi.deleteBillingContact(id, orgId)
            .then(res => res.data)
        })
        : [new Promise((resolve, reject) => {
          resolve()
        })]

      const addPromises = toAdd.length
        ? toAdd.map(email => {
          return userApi.addBillingContact(email, orgId)
            .then(res => res.data)
        })
        : [new Promise((resolve, reject) => {
          resolve()
        })]

      const allPromises = deletePromises.concat(addPromises)
      Promise.all(allPromises)
        .then(arr => {
          this.getOrganization()
          this.setSnackbar('Billing Contacts successfully updated.')
        })
        .catch(e => {
          this.setSnackbar(e)
        })
        .finally(() => {
          this.formProcessing = false
        })
    },

    toggleSeat () {
      if (this.isBroadsignAdsOrganization && this.hasBuyerSeat) {
        this.seat = [this.orgInfo.name, this.orgInfo.currency.code, this.organizationId].join('-')
      }
      this.seatToggled = !this.seatToggled
    },

    syncReachSeat () {
      if (this.isBroadsignAdsOrganization) {
        if (this.hasBuyerSeat) {
          this.seatLoading = true

          return userApi.createOrganizationSeat(this.rawOrg.id)
            .then(seat => {
              this.seat = seat.name
              this.rawOrg.seat = seat.name
              this.setSnackbar('Seat successfully created.')
            })
            .catch(e => {
              this.setSnackbar(e)
            })
            .finally(() => {
              this.seatLoading = false
            })
        } else {
          return userApi.deleteOrganizationSeat(this.rawOrg.id)
            .then(() => {
              this.rawOrg.seat = null
              this.setSnackbar('Seat successfully deleted.')
            })
            .catch(e => {
              this.setSnackbar(e)
            })
            .finally(() => {
              this.seatLoading = false
            })
        }
      }

      return Promise.resolve()
    },

    updatePaymentMethod () {
      billingService.addPaymentMethod(this.paymentMethod.key, this.organizationId)
        .then(addedPaymentMethod => {
          if (addedPaymentMethod.id) {
            this.getOrganization()
            tracking.sendEvent(['ga'], 'updatedPaymentMethod', { label: this.paymentMethod.key })
            this.setSnackbar('Payment Type successfully updated.')
          }
        })
        .catch(e => {
          this.setSnackbar(e)
        })
        .finally(() => {
          this.formProcessing = false
        })
    },

    setSnackbar (param) {
      let type = 'success'
      let msg = ''

      if (typeof param === 'string') {
        msg = param
      } else {
        type = 'error'
        try {
          const error = param.response.data.errors[0]
          msg = error.message
        } catch {
          msg = param.toString()
        }
      }

      this.$store.commit('snackbar/setSnackbar', { type, msg })
    },
    switchSection (entries) {
      const newSection = userService.getOrganizationSettingsSection(entries, this.$route.hash, this.initialScrollDone)
      if (newSection) {
        if (newSection.updateUrlHash) {
          this.$vuetify.goTo(this.$route.hash, { duration: 0 })
          this.initialScrollDone = true
        } else this.visibleSection = newSection.visibleSection
      }
    },
    scrollToSection (section) {
      const goTo = userService.organizationSettingsScrollToSection(section, this.visibleSection, this.$route.hash)
      if (goTo) {
        this.$router.push('#' + goTo)
        this.initialScrollDone = true
      }
    },
    async toggleApiAccess (toEnable) {
      try {
        this.apiKeyLoading = true
        if (toEnable) await userApi.enableOrganizationApiKey(this.organizationId)
        else await userApi.disableOrganizationApiKey(this.organizationId)
        this.apiKey = await userApi.getOrganizationApiKey(this.organizationId)
        this.apiKeyLoading = false
        this.setSnackbar(`API key successfully ${toEnable ? 'enabled' : 'disabled'}`)
        tracking.sendEvent(['ga'], toEnable ? 'enabledApiAccess' : 'disabledApiAccess')
      } catch (err) {
        this.setSnackbar(err)
        const obj = { err, organizationId: this.organizationId }
        const user = this.$store.getters['user/getProfile']
        if (user?.id) obj.userId = user.id
        this.$logger.info('organizationSettings.methods.toggleApiAccess', obj)
      }
    }
  }
}
</script>

<style lang="stylus" scoped>

.font-size-16 {
  font-size: 16px;
}
</style>
