export default defaultCurrencyValues

function defaultCurrencyValues (currency = null) {
  const options = {
    CAD: {
      currency: 'CAD',
      currencySymbolString: '$'
    },
    USD: {
      currency: 'USD',
      currencySymbolString: '$'
    },
    AUD: {
      currency: 'AUD',
      currencySymbolString: '$'
    },
    EUR: {
      currency: 'EUR',
      currencySymbolString: '€'
    },
    GBP: {
      currency: 'GBP',
      currencySymbolString: '£'
    }
  }
  return !currency ? options : options[currency]
}
