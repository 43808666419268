<template lang="pug">
  v-app-bar(app color='white' fixed dense clipped elevate-on-scroll)
    v-btn.logo.px-1.mr-4(v-if='appReady && (customLogo || componentConfig.branding.defaultLogo)' color='primary' text router-link to='/campaigns' style='min-width: unset')
      img(v-if='customLogo' :src='customLogo' height='33')
      img(v-else :src='componentConfig.branding.defaultLogo' height='29')

    v-tabs#nav-tabs.hidden-sm-and-down(v-if='appReady' :value='activeTab' :hide-slider='activeTab === null')
      v-tab.nav-item.dashboard(v-if='componentConfig.isReportsShown' text router-link to='/dashboard' style='min-width: 50px;')
        v-icon
          | mdi-view-dashboard
      v-tab.nav-item(v-for='navItem in navItems' :key='navItem' :class='navItem' text router-link :to="'/'.concat(navItem)") {{ formatTabLabel(navItem) }}

    v-spacer

    v-toolbar-items(v-if='appReady')
      v-btn(icon :href="componentConfig.links.navbarHelp" target="_blank")
        v-icon
          | mdi-help-circle
      menuAddContent(v-if='!$vuetify.breakpoint.smAndDown')
      menuNotifications(v-if='isTokenSet')
      menuProfile(
        v-if='!$vuetify.breakpoint.smAndDown'
        :user-display-name='userDisplayName'
        :user-profile-link='userProfileLink'
        :user-organization='userOrganization'
      )

    v-app-bar-nav-icon.hidden-sm-and-up(v-if='appReady' @click.stop='drawerClicked')
</template>
<style scoped>
  .v-bar--underline{
    border-width: 0 0 thin;
    border-style: solid;
    border-bottom-color: rgba(0,0,0,.12)!important;
  }
</style>

<script>
import menuProfile from '@/components/menuProfile.vue'
import menuAddContent from '@/components/menuAddContent.vue'
import menuNotifications from '@/components/menuNotifications.vue'
import componentConfigService from '@/services/componentConfig'
import helpers from '@/services/helpers.service'
import { OrganizationTypeEnum } from '@/enums'
import flags from '@/plugins/rox/flags'

export default {
  components: {
    menuProfile,
    menuAddContent,
    menuNotifications
  },
  props: ['appReady', 'userDisplayName', 'userProfileLink', 'userOrganization'],
  computed: {
    isTokenSet () {
      return this.$store.getters['user/getToken']
    },
    navItems () {
      const items = [
        {
          name: 'campaigns',
          isShown: true
        },
        {
          name: 'reports',
          isShown: this.componentConfig.isReportsShown
        },
        {
          name: 'marketplace',
          isShown: this.canSeeMarketplace
        },
        {
          name: 'audiences',
          isShown: this.componentConfig.audiences.canSaveLoadAudience
        },
        {
          name: 'creatives',
          isShown: this.can('creative')
        },
        {
          name: 'billing',
          isShown: this.componentConfig.isBillingShown
        },
        {
          name: 'auction-packages',
          isShown: this.canSeeAuctionPackage
        }
      ]

      return items.filter(item => item.isShown).map(item => item.name)
    },
    activeTab () {
      const visibleTabs = [...this.navItems]
      if (this.componentConfig.isReportsShown) visibleTabs.unshift('dashboard')

      for (const tab of visibleTabs) {
        if (this.$route.path.includes(tab)) return visibleTabs.indexOf(tab)
      }
      if (this.$route.path.includes('proposals')) return visibleTabs.indexOf('campaigns')

      const indexOf = {
        firstTab: 0,
        noTabSelected: null
      }
      return this.componentConfig.isReportsShown ? indexOf.firstTab : indexOf.noTabSelected
    },
    canSeeMarketplace () {
      const org = this.$store.getters['user/getOrganization']
      return Boolean(org.seatName || (org.type === OrganizationTypeEnum.PARTNER_DSP && flags.canSeeMarketplaceTabAsDspPartner.isEnabled()))
    },
    canSeeAuctionPackage () {
      return this.$store.getters['general/isAuctionPackageVisible'] && this.$store.getters['general/isAuctionPackageMenuVisible']
    },
    customLogo () {
      return this.$store.getters['general/getOrganizationLogo']
    },
    componentConfig () {
      return componentConfigService(this.$store.getters['user/isForAdServer'])
    }
  },
  methods: {
    can (entity) {
      return this.$store.getters['user/permissions'](entity).read.default
    },
    drawerClicked () {
      this.$emit('toggleDrawer')
    },
    formatTabLabel (tab) {
      if (!tab) return ''
      return tab.split('-').map(word => helpers.capitalizeString(word)).join(' ')
    }
  }
}
</script>
