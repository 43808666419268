<template lang="pug">
v-form(ref="auctionPackage" v-model='editFormValid')
  v-row.mb-1(row wrap justify="end")
    v-col.py-0
      v-text-field.mt-0#auction-package-name-field(
        label='Auction Package Name'
        v-model='enteredData.name'
        counter='100'
        :rules="[rules.required, rules.counter]")

    v-col.py-0(cols=12 sm=6 lg=4)
      v-text-field.mt-0#auction-package-external-id-field(
        label='External ID'
        v-model='enteredData.externalId')
  div.my-1
    scheduleSection(
      @storeUpdated='$emit("updateForecast")'
      :host="{ name: 'createCampaign' }"
      :isProposalLine='false'
      :isOnAuctionPackagePage='true'
      :parent='parent'
    )

  creativeFormatSection
</template>
<script>
import scheduleSection from '@/components/audience.schedule.vue'
import creativeFormatSection from '@/components/auctionPackageCreativeFormatForm.vue'

export default {
  components: {
    scheduleSection,
    creativeFormatSection
  },

  props: {
    name: {
      type: String,
      default: () => ''
    },
    externalId: {
      type: String,
      default: () => ''
    },
    parent: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      editFormValid: false,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 100 || 'Too long.'
      },
      enteredData: {
        name: this.name,
        externalId: this.externalId
      }
    }
  },
  watch: {
    enteredData: {
      immediate: true,
      handler () {
        this.$store.commit('auctionPackage/storeAuctionPackageDetails', this.enteredData)
        const inputMissing = !this.enteredData.name
        this.$emit('inputMissing', inputMissing)
      },
      deep: true
    }
  },
  mounted () {
    this.$root.$on('validateAuctionPackageForm', () => {
      if (this.$refs.auctionPackage) this.$refs.auctionPackage.validate()
    })
  }
}
</script>
