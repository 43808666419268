import geoHelper from '@/services/geoHelpers/geo.helper.js'
import geoTargetOperations from '@/services/geoHelpers/geo.target.operations.js'
import geoFetcher from '@/services/geoHelpers/geo.fetcher.js'
import geoService from '@/services/geo.service'
import flags from '@/plugins/rox/flags'
import axios from 'axios'

export default {
  getGeoTargetsJsonUpdater
}

function getGeoTargetsJsonUpdater (selectedGeoTargets, currentMapViewPort, disableMapRecentering) {
  if (!currentMapViewPort && (disableMapRecentering || selectedGeoTargets?.length !== 1)) {
    return {
      getUpdatedGeoTargets: null,
      cancelTokenSource: null
    }
  }

  const cancelTokenSource = axios.CancelToken.source()

  const getUpdatedGeoTargets = async () => {
    let geosWithinViewPort
    if (!disableMapRecentering && selectedGeoTargets.filter(geoTarget => geoTarget.type !== 'point_of_interest')?.length === 1) {
      geosWithinViewPort = JSON.parse(JSON.stringify(selectedGeoTargets))
    } else {
      geosWithinViewPort = await getGeosWithinViewPort(selectedGeoTargets, currentMapViewPort, cancelTokenSource.token)
    }

    return await fetchGeoJsonFromUrlForGeosWithinViewport(geosWithinViewPort, selectedGeoTargets, cancelTokenSource.token)
  }

  return { getUpdatedGeoTargets, cancelTokenSource }
}

async function getGeosWithinViewPort (originalGeoTargets, currentMapViewPort, cancelToken) {
  const initialGeoTargetsCopy = geoHelper.getGeoTargetCopyWithIndex(originalGeoTargets)

  const stateAndCountryTargets = geoTargetOperations.filterGeoTargetsByType(initialGeoTargetsCopy, ['postal_code', 'city'], false)
  let cityTargets = []
  let postalCodesTargets = []

  if (currentMapViewPort.zoom > flags.postalCodeZoomBreakpoint.getValue()) {
    const filteredGeoTargets = geoTargetOperations.filterGeoTargetsByType(initialGeoTargetsCopy, ['postal_code'])
    postalCodesTargets = await geoFetcher.fetchGeoInBoundingBox(filteredGeoTargets, currentMapViewPort, cancelToken)
  }

  if (currentMapViewPort.zoom > flags.cityZoomBreakpoint.getValue()) {
    const filteredGeoTargets = geoTargetOperations.filterGeoTargetsByType(initialGeoTargetsCopy, ['city'])
    cityTargets = await geoFetcher.fetchGeoInBoundingBox(filteredGeoTargets, currentMapViewPort, cancelToken)
  }

  return geoTargetOperations.combineGeoTargets(stateAndCountryTargets, postalCodesTargets, cityTargets)
}

async function fetchGeoJsonFromUrlForGeosWithinViewport (geosWithinViewPort, originalGeoTargets, cancelToken) {
  if (!flags.isShapeLoadedByMapViewportEnabled.isEnabled()) return originalGeoTargets

  const initialGeoTargetsCopy = JSON.parse(JSON.stringify(originalGeoTargets))

  const { geosWithinViewPortToLoad, geoRadiusesWithinViewPort } = geosWithinViewPort.reduce((acc, geo) => {
    if (isRadius(geo)) {
      acc.geoRadiusesWithinViewPort.push(geo)
    } else {
      acc.geosWithinViewPortToLoad.push(geo)
    }
    return acc
  }, { geosWithinViewPortToLoad: [], geoRadiusesWithinViewPort: [] })

  let loadedGeosWithGeoJson = await geoFetcher.fetchGeosWithJson(geosWithinViewPortToLoad, cancelToken)
  loadedGeosWithGeoJson = geoTargetOperations.combineGeoTargets(loadedGeosWithGeoJson, geoRadiusesWithinViewPort)

  geoHelper.setGeoJsonOnGeosWithinViewPort(initialGeoTargetsCopy, loadedGeosWithGeoJson)

  return initialGeoTargetsCopy
}

function isRadius (geo) {
  return typeof geo.radius === 'number' ? true : geoService.radiusFormatting(geo.radius, 'toNumber')
}
