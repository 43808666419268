import { render, staticRenderFns } from "./auctionPackageForm.vue?vue&type=template&id=b262b38e&lang=pug"
import script from "./auctionPackageForm.vue?vue&type=script&lang=js"
export * from "./auctionPackageForm.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports